import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Sort,
  Page,
  RecordClickEventArgs,
} from '@syncfusion/ej2-react-grids'

import * as helpers from 'common/helpers'
import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'

import { DocumentStatusBadge } from 'docgen/components/document/DocumentStatusBadge'

import { useGetAllMyDocuments } from 'docgen/hooks/useDocuments'
import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'
import { useGetAllClients } from 'coredata/hooks/useClients'
import { ErrorContainer } from 'common/components'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllDocumentsGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()

  const allDocumentsData = useGetAllMyDocuments()
  const allClientsData = useGetAllClients()

  function recordClicked(args: RecordClickEventArgs) {
    const clickedDocumentRecord = args.rowData as DocumentAggregate
    navigate('/docgen/document/' + clickedDocumentRecord.id)
  }

  const documentClientTemplate = (data: DocumentAggregate) => {
    let docClientId = data.clientId
    let client = allClientsData.data?.find((f) => {
      return f.id === docClientId
    })
    return client ? client.name : docClientId
  }

  const createdOnTemplate = (data: DocumentAggregate) => {
    return helpers.formatDate(data.auditData?.createdOn)
  }

  const createdByTemplate = (data: DocumentAggregate) => {
    let name = data.auditData?.createdBy
      ? helpers.usernameToName(data.auditData?.createdBy)
      : data.auditData?.createdBy
    return name
  }

  return (
    <>
      {allDocumentsData == null && <p>You don't have any documents yet. </p>}
      {(allDocumentsData.isFetching || allClientsData.isFetching) && <Loading />}
      {allDocumentsData.error && <ErrorContainer error={allDocumentsData.error} />}
      {!allDocumentsData.isFetching && !allClientsData.isFetching && (
        <StyledGrid
          dataSource={allDocumentsData.data}
          allowPaging={true}
          allowFiltering={false}
          allowSorting={true}
          pageSettings={{ pageSize: 5, pageCount: 5 }}
          enableHover={true}
          locale={locale}
          allowSelection={true}
          recordClick={recordClicked.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Document Naam"
              field="name"
              width="130"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Client"
              field="clientId"
              width="100"
              textAlign="Left"
              template={documentClientTemplate}
              filterTemplate={documentClientTemplate}
            />
            <ColumnDirective
              headerText="Status"
              field="status"
              width="60"
              template={DocumentStatusBadge}
              textAlign="Left"
            />
            <ColumnDirective
              headerText="Created On"
              field="auditData.createdOn"
              template={createdOnTemplate}
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Created By"
              field="auditData.createdBy"
              template={createdByTemplate}
              width="100"
              textAlign="Left"
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page]} />
        </StyledGrid>
      )}
    </>
  )
}

export default AllDocumentsGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }
  cursor: pointer;
`
