import { Route, Routes, Navigate } from 'react-router-dom'

import { userRoles } from 'services/auth/userRoles'

/* Core */
import HomeView from 'common/views/HomeView'
import ConfigView from 'common/views/ConfigView'
import SignOutView from '../../common/views/SignOutView'

/* Client */
import ClientsView from '../../coredata/views/client/AllClientsView'
import ClientView from '../../coredata/views/client/ClientView'
import AddClientView from '../../coredata/views/client/AddClientView'
/* Office */
import OfficesView from '../../coredata/views/office/AllOfficesView'
import AddOfficeView from '../../coredata/views/office/AddOfficeView'
import OfficeView from '../../coredata/views/office/OfficeView'
/* DocGen */
import AllDocumentsView from '../../docgen/views/document/AllDocumentsView'
import AddDocumentView from '../../docgen/views/document/AddDocumentView'
import DocumentView from '../../docgen/views/document/DocumentView'
import AddDocumentTemplateView from 'docgen/views/documenttemplate/AddDocumentTemplateView'
import AllDocumentTemplatesView from 'docgen/views/documenttemplate/AllDocumentTemplatesView'
import DocumentTemplateView from 'docgen/views/documenttemplate/DocumentTemplateView'
import { GetLoggedInUser } from 'services/auth/authService'
import { IDTokenClaims } from 'services/auth/authModels'

/* AdminData */
import AllCustomersView from '../../admindata/views/AllCustomersView'
import AllTimesheetsView from '../../admindata/views/AllTimesheetsView'

enum RoutesList {
  root = '/',
  signout = '/signout',
  config = '/config',
  clients = '/clients',
  clientadd = '/client/add',
  clientview = '/client/:clientId',
  documents = '/docgen/documents',
  documentadd = '/docgen/document/add',
  documentview = '/docgen/document/:id',
  offices = '/offices',
  officeview = '/office/:officeId',
  officeadd = '/office/add',
  documenttemplates = '/docgen/documenttemplates',
  documenttemplateadd = '/docgen/documenttemplate/add',
  documenttemplateview = '/docgen/documenttemplate/:id',
  adminconsultCustomers = '/admindata/adminconsult/customers',
  adminconsultTimesheets = '/admindata/adminconsult/timesheets',
}

const AdminRoute = ({ children, requiredRole: string }) => {
  const user = GetLoggedInUser()
  let claims: IDTokenClaims

  if (user.idTokenClaims) {
    claims = user.idTokenClaims as IDTokenClaims
    if (claims.roles?.includes(userRoles.portalAdmins)) return children
  }

  return <Navigate to="/" replace />
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={RoutesList.root} element={<HomeView />} />
      <Route path={RoutesList.signout} element={<SignOutView />} />

      <Route
        path={RoutesList.config}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <ConfigView />
          </AdminRoute>
        }
      />

      <Route path={RoutesList.clients} element={<ClientsView />} />
      <Route path={RoutesList.clientadd} element={<AddClientView />} />
      <Route path={RoutesList.clientview} element={<ClientView />} />

      <Route
        path={RoutesList.offices}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <OfficesView />
          </AdminRoute>
        }
      />
      <Route
        path={RoutesList.officeview}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <OfficeView />
          </AdminRoute>
        }
      />
      <Route
        path={RoutesList.officeadd}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <AddOfficeView />
          </AdminRoute>
        }
      />

      <Route path={RoutesList.documents} element={<AllDocumentsView />} />
      <Route path={RoutesList.documentadd} element={<AddDocumentView />} />
      <Route path={RoutesList.documentview} element={<DocumentView />} />

      <Route
        path={RoutesList.documenttemplates}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <AllDocumentTemplatesView />
          </AdminRoute>
        }
      />
      <Route
        path={RoutesList.documenttemplateadd}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <AddDocumentTemplateView />
          </AdminRoute>
        }
      />
      <Route
        path={RoutesList.documenttemplateview}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <DocumentTemplateView />
          </AdminRoute>
        }
      />

      <Route
        path={RoutesList.adminconsultCustomers}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <AllCustomersView />
          </AdminRoute>
        }
      />

      <Route
        path={RoutesList.adminconsultTimesheets}
        element={
          <AdminRoute requiredRole={userRoles.portalAdmins}>
            <AllTimesheetsView />
          </AdminRoute>
        }
      />
    </Routes>
  )
}

export default AppRoutes
