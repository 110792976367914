import { useNavigate } from 'react-router'
import { format } from 'date-fns'

export const isValidEmail = (email: string): boolean => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

export const appendScript = (scriptToAppend: string) => {
  const script = document.createElement('script')
  script.src = scriptToAppend
  script.async = true
  document.body.appendChild(script)
}

export const toCamelCase = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const toInitials = (text: string | undefined): string => {
  if (text === undefined) return ''

  return text
    .split(' ')
    .map((n) => n[0])
    .join('')
}

export const usernameToName = (text: string | undefined): string => {
  if (text === undefined) return ''

  return (
    text.charAt(0).toUpperCase() +
    text
      .slice(1)
      .toLowerCase()
      .slice(0, text.indexOf('@') - 1)
  )
}

export const toLastPartOfUrl = (text: string | undefined): string => {
  if (text === undefined) return ''

  return decodeURI(text.substring(text.lastIndexOf('/')))
}

export function useRouteTo(path: string) {
  const navigate = useNavigate()
  navigate(path)
}

export const formatDate = (text: string | undefined): string => {
  if (text === undefined) return ''

  const date = format(new Date(text), 'dd/MM/yyyy HH:mm')

  return date
}
export const formatDateFromDate = (text: Date | undefined): string => {
  if (text === undefined) return ''

  const date = format(new Date(text), 'dd/MM/yyyy')

  return date
}

export const stringIsNumber = (value) => isNaN(Number(value)) === false

export const stringToNumber = (text: string) => {
  return Number(text)
}

export const formatBase64String = (base64: string): string => {
  return base64.split(',')[1]
}

export const stringIsEmpty = (value): boolean => {
  if (value === '' || value === undefined || value == null) return true
  return false
}

export const checkEnumInList = (enumValue, enumList): boolean => {
  return Object.values(enumList).includes(enumValue)
}

export const minutesToHoursString = (minutes?: number): string => {
  if (minutes === undefined) {
    return '0:00' // Return a default value of "0:00" if minutes is undefined
  }

  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60
  const paddedMinutes = minutesLeft.toString().padStart(2, '0')
  return `${hours}:${paddedMinutes}`
}
