export const TRANSLATIONS = {
  'en-US': {
    global: {
      sidebarMenu: {
        dashboard: 'Dashboard',
        clients: 'Clients',
        offices: 'Offices',
        docgen: 'Document Generator',
        apps: 'Applications',
      },
      accountMenu: {
        settings: 'Settings',
        profile: 'My Profile',
        signout: 'Sign Out',
      },
      actions: {
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        update: 'Update',
      },
    },
    core: {
      clients: {
        clients: 'Clients',
      },
    },
    docgen: {
      document: {
        generateStartedMessage: 'New document is being generated...',
        generateReadyMessage: 'New document is ready to be downloaded!',
      },
    },
  },
  'fr-BE': {
    global: {
      sidebarMenu: {
        dashboard: 'Dashboard',
        clients: 'Entreprises',
        offices: 'Offices',
        docgen: 'Générateur de documents',
        apps: 'Applications',
      },
      accountMenu: {
        settings: 'Paramètres',
        profile: 'Mon profile',
        signout: 'Se déconnecter',
      },
    },
    core: {
      clients: {
        clients: 'Entreprises',
      },
    },
    docgen: {
      document: {
        generateStartedMessage: 'Génération du nouveau document en cours...',
        generateReadyMessage: 'Nouveau document est prêt a être télécharger!',
      },
    },
  },
  'nl-BE': {
    global: {
      sidebarMenu: {
        dashboard: 'Dashboard',
        clients: 'Bedrijven',
        offices: 'Kantoren',
        docgen: 'Document Generator',
        apps: 'Applicaties',
      },
      accountMenu: {
        settings: 'Instellingen',
        profile: 'Mijn profiel',
        signout: 'Afmelden',
      },
    },
    core: {
      clients: {
        clients: 'Bedrijven',
      },
    },
    docgen: {
      document: {
        generateStartedMessage: 'Nieuw document wordt klaargemaakt...',
        generateReadyMessage: 'Nieuw document is klaar en kan gedownload worden!',
      },
    },
  },
}

export const SYNCFUSIONTRANSLATIONS = {
  'fr-BE': {
    grid: {
      EmptyRecord: 'Aucun enregistrement à afficher',
      InvalidFilterMessage: 'Données de filtrage invalides',
      UnGroup: 'Cliquez ici pour dégrouper ',
    },
    pager: {
      currentPageInfo: '{0} sur {1} pages',
      totalItemsInfo: '({0} résultats)',
    },
  },
}
