import { Configuration, RedirectRequest } from '@azure/msal-browser'
import {
  GetMsalTenantAuthorityUri,
  GetMsalClientScope,
  GetMsalClientId,
  GetMsalCacheLocation,
  GetLoginRedirectUri,
} from 'services/appsettings'

export const msalConfig: Configuration = {
  auth: {
    clientId: GetMsalClientId(),
    authority: GetMsalTenantAuthorityUri(),
    redirectUri: GetLoginRedirectUri(),
    postLogoutRedirectUri: GetLoginRedirectUri(),
  },
  cache: {
    cacheLocation: GetMsalCacheLocation(),
    storeAuthStateInCookie: true,
  },
}
export const loginRequest: RedirectRequest = {
  scopes: [GetMsalClientScope(), 'User.Read'],
  prompt: 'select_account',
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
