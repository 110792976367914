import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'

export const DocumentStatusBadge = (data: DocumentAggregate) => {
  //console.log(data)
  let status = data.status
  let statusClass = 'bg-light text-dark'
  let statusText = data.status?.toString()

  switch (status?.toString()) {
    case 'Draft':
      statusClass = 'badge bg-light text-dark'
      break
    case 'Generated':
      statusClass = 'badge bg-secondary'
      break
    case 'SendRequested':
      statusClass = 'badge bg-warning text-dark'
      statusText = 'Sending...'
      break
    case 'SendSucceeded':
      statusClass = 'badge bg-primary'
      statusText = 'Send'
      break
    case 'SendFailed':
      statusClass = 'badge bg-danger'
      statusText = 'Send Failed'
      break
    case 'Signed':
      statusClass = 'badge bg-success'
      break
    default:
      break
  }

  return <span className={'badge ' + statusClass}>{statusText}</span>
}
