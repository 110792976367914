import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { InteractionStatus } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react'
import { GetWebApiBaseUri } from 'services/appsettings'
import { loginRequest } from 'services/auth/authConfig'
import { queryClient } from 'services/http/queryClient'
import AxiosProvider from 'services/http/axiosClient'
import { LocalizationProvider } from 'localize-react'
import { TRANSLATIONS } from 'common/translations'
import { MainLayout } from './common/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'common/styles/App.css'
import 'common/styles/bootstrap5.min.css'

const App = () => {
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest)
  }

  if (process.env.NODE_ENV !== 'production') {
    displayDevInfo()
  }

  return (
    <LocalizationProvider disableCache locale="en-US" translations={TRANSLATIONS}>
      <AuthenticatedTemplate>
        <AxiosProvider>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <BrowserRouter>
              <MainLayout />
            </BrowserRouter>
          </QueryClientProvider>
        </AxiosProvider>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <div>Signing in...</div>
      </UnauthenticatedTemplate>
    </LocalizationProvider>
  )
}

export default App

function displayDevInfo() {
  console.log(`Backend Url: ${GetWebApiBaseUri()}`)
}
