import { useMutation, useQuery } from 'react-query'
import { useAxios, ProviderValue } from 'services/http/axiosClient'
import { ResponseError } from 'services/http/types'
import { AdminConsultCustomer } from '../models/clientsync/Customer'
import { TimeSheet } from '../models/timesheets/TimeSheet'
import { stringIsEmpty } from 'common/helpers'
import { DownloadTimeSheetCmd } from 'admindata/contracts/timesheets/DownloadTimeSheetCmd'
import { GenerateTimeSheetCmd } from 'admindata/contracts/timesheets/GenerateTimeSheetCmd'
import { SendTimeSheetCmd } from 'admindata/contracts/timesheets/SendTimeSheetCmd'
import { ReturnId } from 'common/models/ReturnTypes'
import { queryClient } from 'services/http/queryClient'
export const getAllCustomersKey = 'getAllCustomers'
export const getAllTimesheetsKey = 'getAllTimesheets'
export const downloadTimeSheetKey = 'downloadTimesheet'
export const generateTimeSheetKey = 'generateTimesheet'
export const generateMultiTimeSheetKey = 'generateMultiTimesheet'
export const sendTimeSheetKey = 'sendTimesheet'

export function useGetAllCustomers() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<AdminConsultCustomer[], ResponseError>(getAllCustomersKey, async () => {
    const { data } = await axiosClient.get<AdminConsultCustomer[]>(
      '/admindata/adminconsult/customers'
    )
    return data
  })
}

export function useGetAllTimesheets(dateFrom: string | undefined, dateTo: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<TimeSheet[] | undefined, ResponseError>(getAllTimesheetsKey, async () => {
    if (stringIsEmpty(dateFrom) || stringIsEmpty(dateTo)) return undefined
    const { data } = await axiosClient.get<TimeSheet[]>(
      // eslint-disable-next-line no-template-curly-in-string
      '/admindata/adminconsult/timesheets/dateFrom=' + dateFrom + '&dateTo=' + dateTo
    )
    return data
  })
}

export function useDownloadTimeSheet() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<Blob | undefined, ResponseError, DownloadTimeSheetCmd, unknown>(
    downloadTimeSheetKey,
    async (downloadTimeSheetCmd) => {
      //console.log('API call download cmd', downloadTimeSheetCmd)
      const { data } = await axiosClient.post<Blob>(
        `/admindata/adminconsult/timesheet/download`,
        downloadTimeSheetCmd,
        {
          responseType: 'blob',
        }
      )
      const blob = new Blob([data], { type: 'application/pdf' })
      //console.log('Blob from useDownloadTimesheet hook', blob)
      return blob
    }
  )
}

export function useGenerateTimeSheet() {
  const axiosClient = useAxios() as ProviderValue
  let timesheet: GenerateTimeSheetCmd
  return useMutation<string | undefined, ResponseError, GenerateTimeSheetCmd, unknown>(
    generateTimeSheetKey,
    async (generateTimeSheetCmd) => {
      //timesheetid = generateTimeSheetCmd.timeSheet.timeSheetId
      //console.log('API call generate cmd', generateTimeSheetCmd)
      timesheet = generateTimeSheetCmd
      const { data } = await axiosClient.post<ReturnId>(
        `admindata/adminconsult/timesheet/generate`,
        generateTimeSheetCmd
      )
      return data.id
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<TimeSheet[] | undefined>([getAllTimesheetsKey], (old) => {
          if (old) {
            const updateddata = old.map((item) =>
              item.timeSheetId === timesheet.timeSheet.timeSheetId
                ? { ...item, pdfGenerated: true }
                : item
            )
            return updateddata
          }
        })
      },
    }
  )
}

export function useGenerateMultiTimeSheet() {
  const axiosClient = useAxios() as ProviderValue
  let generatedTimesheets: GenerateTimeSheetCmd[]
  return useMutation<string | undefined, ResponseError, GenerateTimeSheetCmd[], unknown>(
    generateMultiTimeSheetKey,
    async (generateMultiTimeSheetCmd: GenerateTimeSheetCmd[]) => {
      generatedTimesheets = generateMultiTimeSheetCmd
      const { data } = await axiosClient.post<ReturnId>(
        `admindata/adminconsult/timesheet/generatemulti`,
        generateMultiTimeSheetCmd
      )

      return 'ok'
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<TimeSheet[] | undefined>([getAllTimesheetsKey], (old) => {
          if (old) {
            const updateddata = old.map((item) =>
              generatedTimesheets.some((i) => i.timeSheet.timeSheetId === item.timeSheetId)
                ? { ...item, pdfGenerated: true }
                : item
            )
            return updateddata
          }
        })
      },
    }
  )
}

export function useSendTimeSheet() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string | undefined, ResponseError, SendTimeSheetCmd, unknown>(
    sendTimeSheetKey,
    async (sendTimeSheetCmd) => {
      //console.log('API call download cmd', downloadTimeSheetCmd)
      const { data } = await axiosClient.post<ReturnId>(
        `/admindata/adminconsult/timesheet/send`,
        sendTimeSheetCmd
      )
      return data.id
    }
  )
}

export function useSendMultiTimeSheet() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string | undefined, ResponseError, SendTimeSheetCmd[], unknown>(
    sendTimeSheetKey,
    async (sendTimeSheetCmd) => {
      //console.log('API call download cmd', downloadTimeSheetCmd)
      const { data } = await axiosClient.post<ReturnId>(
        `/admindata/adminconsult/timesheet/sendmulti`,
        sendTimeSheetCmd
      )
      return data.id
    }
  )
}
