import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { Loading, ErrorContainer, BoxContainer, ButtonEdit, ButtonDelete } from 'common/components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import styled from 'styled-components'
import {
  DialogComponent,
  ButtonPropsModel,
  AnimationSettingsModel,
} from '@syncfusion/ej2-react-popups'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { useGetOfficeById, useChangeOfficeDetails } from 'coredata/hooks/useOffices'
import { ChangeOfficeDetails } from 'coredata/contracts/office/ChangeOfficeDetails'
import { Address } from 'coredata/models/BaseTypes'

L10n.load(SYNCFUSIONTRANSLATIONS)

const OfficeView = () => {
  const { locale } = useLocalize()
  let { officeId } = useParams()

  // Global
  const officeData = useGetOfficeById(officeId || '')

  // Edit Details
  const changeOfficeDetails = useChangeOfficeDetails()
  let promptDialogInstance = useRef<DialogComponent>(null)
  let editOfficeButtons: ButtonPropsModel[]
  let animationSettings: AnimationSettingsModel
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  let nameInput: TextBoxComponent | null
  let emailInput: TextBoxComponent | null
  let officeNrInput: TextBoxComponent | null
  let aStreetInput: TextBoxComponent | null
  let aNrInput: TextBoxComponent | null
  let aMailboxInput: TextBoxComponent | null
  let aZipInput: TextBoxComponent | null
  let aCityInput: TextBoxComponent | null
  let aCountryCodeInput: TextBoxComponent | null
  editOfficeButtons = [
    {
      click: () => {
        const changedDetails = {
          id: officeId,
          externalId: officeNrInput?.value,
          name: nameInput?.value,
          email: emailInput?.value,
          address: {
            countryIso2: aCountryCodeInput?.value,
            zipCode: aZipInput?.value,
            city: aCityInput?.value,
            street: aStreetInput?.value,
            number: aNrInput?.value,
            mailbox: aMailboxInput?.value,
          } as Address,
        } as ChangeOfficeDetails

        changeOfficeDetails.mutate(changedDetails)
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Save', isPrimary: true },
    },
    {
      click: () => {
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Cancel' },
    },
  ]
  animationSettings = { effect: 'None' }
  function btnEditOfficeClick() {
    setEditDialogOpen(true)
  }
  function editOfficeDialogClose() {
    setEditDialogOpen(false)
  }

  return (
    <>
      {officeData.isFetching && <Loading />}
      {officeData.error && <ErrorContainer error={officeData.error} />}
      {!officeData.isFetching && !officeData.error && (
        <>
          <div className="container-fluid">
            <TitleContainer title={officeData.data?.name || 'Office not found'} />
            <ActionsBarContainer>
              <ButtonEdit onClick={btnEditOfficeClick}></ButtonEdit>{' '}
              <ButtonDelete
                disabled={true}
                onClick={() => alert('Are you sure you want to delete the office?')}
              ></ButtonDelete>{' '}
            </ActionsBarContainer>
          </div>
          <ContentContainer>
            <div className="row" id="dialogTarget">
              <div className="col-6">
                <BoxContainer title="Office information">
                  <StyledDetailsContainer>
                    Office Nr: <b>{officeData.data?.externalId}</b>
                    <br />
                    Office Name: <b>{officeData.data?.name}</b>
                    <br />
                    Email: <b>{officeData.data?.email?.value}</b>
                    <br />
                    <br />
                    Address: <br />
                    <b>
                      {officeData.data?.address?.street} {officeData.data?.address?.number}{' '}
                      {officeData.data?.address?.mailbox
                        ? '- ' + officeData.data?.address?.mailbox
                        : ''}
                    </b>
                    <br />
                    <b>
                      {officeData.data?.address?.zipCode} {officeData.data?.address?.city}{' '}
                      {officeData.data?.address?.countryIso2}
                    </b>
                    <br />
                  </StyledDetailsContainer>
                </BoxContainer>
              </div>
              <div className="col-6"></div>
            </div>
          </ContentContainer>
          <StyledDialogComponent
            id="promptDialog"
            header={'Edit details of ' + officeData?.data?.name}
            visible={editDialogOpen}
            showCloseIcon={true}
            animationSettings={animationSettings}
            width="auto"
            height="auto"
            ref={promptDialogInstance}
            target="#dialogTarget"
            buttons={editOfficeButtons}
            close={editOfficeDialogClose.bind(this)}
            allowDragging={true}
          >
            {/* Prompt Dialog content  */}
            <table className="e-table" cellSpacing="6px">
              <tbody>
                <tr>
                  <td>
                    <strong>Info:</strong>
                  </td>
                </tr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2} width="400">
                    <TextBoxComponent
                      type="text"
                      placeholder="Name"
                      value={officeData.data?.name}
                      floatLabelType="Always"
                      ref={(n) => (nameInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Office Nr"
                      value={officeData.data?.externalId}
                      floatLabelType="Always"
                      ref={(n) => (officeNrInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="Email"
                      value={officeData.data?.email.value}
                      floatLabelType="Always"
                      ref={(n) => (emailInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <tr>
                  <td>
                    <strong>Address:</strong>
                  </td>
                </tr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="Street"
                      value={officeData.data?.address?.street}
                      floatLabelType="Always"
                      ref={(n) => (aStreetInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Nr"
                      value={officeData.data?.address?.number}
                      floatLabelType="Always"
                      ref={(n) => (aNrInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Mailbox"
                      value={officeData.data?.address?.mailbox}
                      floatLabelType="Always"
                      ref={(n) => (aMailboxInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="City"
                      value={officeData.data?.address?.city}
                      floatLabelType="Always"
                      ref={(n) => (aCityInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="number"
                      placeholder="Zip"
                      value={officeData.data?.address?.zipCode}
                      floatLabelType="Always"
                      ref={(n) => (aZipInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>

                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="CountryCode"
                      value={officeData.data?.address?.countryIso2}
                      floatLabelType="Always"
                      ref={(n) => (aCountryCodeInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
              </tbody>
            </table>
          </StyledDialogComponent>
        </>
      )}
    </>
  )
}

export default OfficeView

const StyledDetailsContainer = styled.div`
  float: left;
`

const StyledDialogComponent = styled(DialogComponent)`
  max-height: unset !important;
  top: 20px !important;
  height: auto !important;
  width: auto !important;
  min-width: 450px !important;
  min-height: 300px !important;
`
const StyledDialogTr = styled.tr``
const StyledDialogTd = styled.td`
  padding: 5px 50px 20px 0;
`
