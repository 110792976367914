import React from 'react'
/* Layout */
import { ContentContainer } from 'common/layout'

import { useIsAuthenticated, useMsal } from '@azure/msal-react'

const SignOutView = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  if (isAuthenticated) instance.logoutRedirect()

  return (
    <>
      <ContentContainer>Signing Out...</ContentContainer>
    </>
  )
}

export default SignOutView
