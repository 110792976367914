import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  IFilter,
  Inject,
  Sort,
  Toolbar,
  Page,
  RecordClickEventArgs,
  ForeignKey,
  ExcelExport,
  Grid,
} from '@syncfusion/ej2-react-grids'

import * as helpers from 'common/helpers'
import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'

import { DocumentStatusBadge } from 'docgen/components/document/DocumentStatusBadge'

import { useGetAllDocuments } from 'docgen/hooks/useDocuments'
import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'
import { useGetAllClients } from 'coredata/hooks/useClients'
import { DocumentTypeText } from './DocumentTypeText'
import { ErrorContainer } from 'common/components'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllDocumentsGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()

  let grid: Grid | null
  const allDocumentsData = useGetAllDocuments()
  const allClientsData = useGetAllClients()

  let toolbarOptions: any = [
    'Search',
    {
      text: 'Export',
      tooltipText: 'Export to Excel',
      prefixIcon: 'bi bi-file-earmark-spreadsheet',
      id: 'excelExport',
      align: 'Right',
    },
  ]

  let check: IFilter = {
    type: 'CheckBox',
  }

  const toolbarClick = (args: any) => {
    console.log(args)
    if (grid && args.item.id === 'excelExport') {
      grid.excelExport()
    }
  }

  function recordClicked(args: RecordClickEventArgs) {
    const clickedDocumentRecord = args.rowData as DocumentAggregate
    navigate('/docgen/document/' + clickedDocumentRecord.id)
  }

  const documentClientTemplate = (data: DocumentAggregate) => {
    let docClientId = data.clientId
    let client = allClientsData.data?.find((f) => {
      return f.id === docClientId
    })
    return client ? client.name : docClientId
  }

  const createdByTemplate = (data: DocumentAggregate) => {
    let name = data.auditData?.createdBy
      ? helpers.usernameToName(data.auditData?.createdBy)
      : data.auditData?.createdBy
    return name
  }

  const createdOnTemplate = (data: DocumentAggregate) => {
    return helpers.formatDate(data.auditData?.createdOn)
  }

  return (
    <>
      {allDocumentsData == null && <p>DocumentsData is null !! </p>}
      {(allDocumentsData.isFetching || allClientsData.isFetching) && <Loading />}
      {allDocumentsData.error && <ErrorContainer error={allDocumentsData.error} />}
      {!allDocumentsData.isFetching && !allClientsData.isFetching && (
        <StyledGrid
          ref={(g) => (grid = g)}
          dataSource={allDocumentsData.data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          allowPaging={true}
          filterSettings={{
            type: 'Menu',
          }}
          allowFiltering={true}
          allowSorting={true}
          sortSettings={{ columns: [{ field: 'number', direction: 'Descending' }] }}
          pageSettings={{ pageSize: 20, pageCount: 5 }}
          enableHover={true}
          locale={locale}
          allowSelection={true}
          recordClick={recordClicked.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Nr"
              field="number"
              width="35"
              textAlign="Left"
              allowFiltering={false}
            />

            <ColumnDirective
              headerText="Document Naam"
              field="name"
              width="130"
              textAlign="Left"
              allowFiltering={true}
            />

            <ColumnDirective
              headerText="Client"
              field="clientId"
              foreignKeyValue="name"
              foreignKeyField="id"
              dataSource={allClientsData.data}
              width="100"
              textAlign="Left"
              filter={check}
            />
            <ColumnDirective
              headerText="Status"
              field="status"
              width="60"
              template={DocumentStatusBadge}
              textAlign="Left"
              filter={check}
            />
            <ColumnDirective
              headerText="Contact"
              field="recipient.name"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />

            <ColumnDirective
              headerText="Type"
              field="type"
              width="40"
              textAlign="Left"
              filter={check}
            />
            <ColumnDirective
              headerText="Created On"
              field="auditData.createdOn"
              template={createdOnTemplate}
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Created By"
              field="auditData.createdBy"
              template={createdByTemplate}
              width="100"
              textAlign="Left"
              filter={check}
            />
          </ColumnsDirective>
          <Inject services={[ForeignKey, Filter, Sort, Toolbar, Page, ExcelExport]} />
        </StyledGrid>
      )}
    </>
  )
}

export default AllDocumentsGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }
  cursor: pointer;
`
