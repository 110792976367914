import styled from 'styled-components'
import * as stylesConfig from 'common/styles/stylesConfig'

interface Props {
  imageBase64?: string | undefined
  initials?: string | undefined
  height?: string
  width?: string
}
interface StylesProps {
  stylesProps: Props
}

export const AvatarBase64 = (props: Props) => {
  if (props.imageBase64)
    return <Base64Logo stylesProps={props} className="e-avatar e-avatar-xlarge" />
  else
    return (
      <InitialsLogo stylesProps={props} className="e-avatar e-avatar-xlarge">
        {props.initials}
      </InitialsLogo>
    )
}

const Base64Logo = styled.div<StylesProps>`
  background-image: url(data:png;base64,${(props) => {
    if (props.stylesProps.imageBase64) return props.stylesProps.imageBase64
  }});

  height: ${(props) => {
    if (props.stylesProps.height) return props.stylesProps.height + ' !important'
    else return '35px'
  }};
  width: ${(props) => {
    if (props.stylesProps.width) return props.stylesProps.width + ' !important'
    else return '35px'
  }};
  background-color: white;
  border: 1px solid #e8e8e8;
`

const InitialsLogo = styled.div<StylesProps>`
  height: ${(props) => {
    if (props.stylesProps.height) return props.stylesProps.height
    else return '35px'
  }};
  width: ${(props) => {
    if (props.stylesProps.width) return props.stylesProps.width
    else return '35px'
  }};
  background-color: ${stylesConfig.colorGrey};
  color: ${stylesConfig.colorWhite};
  border: 1px solid #e8e8e8;
`
