import React from 'react'

import styled from 'styled-components'

interface Props {
  title: string
  subtitle?: string
}
interface PropsStyles {
  stylesProps: Props
}
export const TitleContainer = (props: Props) => {
  return (
    <TitleWrapper stylesProps={props}>
      <StyledTitle>{props.title}</StyledTitle>
      {props.subtitle !== undefined && (
        <StyledSubtitle stylesProps={props}>{props.subtitle}</StyledSubtitle>
      )}
    </TitleWrapper>
  )
}

const TitleWrapper = styled.div<PropsStyles>`
  padding: var(--bs-gutter-x, 0.75rem);
  width: 50%;
  float: left;
`

const StyledTitle = styled.h1`
  font-size: 2rem;
`
const StyledSubtitle = styled.h2<PropsStyles>`
  font-size: 1.2rem;
  color: #6d7276;
`
