export enum BuiltInFields {
  clientName = 'Client.Name',
  clientLogo = 'Client.Logo',
  clientVat = 'Client.VAT',
  clientAddressStreet = 'Client.Address.Street',
  clientAddressNr = 'Client.Address.Nr',
  clientAddressMailbox = 'Client.Address.Mailbox',
  clientAddressPostalcode = 'Client.Address.Postalcode',
  clientAddressCity = 'Client.Address.City',
  clientAddressCountry = 'Client.Address.Country',
  documentName = 'Document.Name',
  documentNr = 'Document.Nr',

  officeName = 'Office.Name',
  officeEmail = 'Office.Email',
  officeAddressCountry = 'Office.Address.Country',
  officeAddressPostalCode = 'Office.Address.PostalCode',
  officeAddressCity = ' Office.Address.City',
  officeAddressStreet = 'Office.Address.Street',
  officeAddressHouseNumber = 'Office.Address.Nr',
  officeAddressMailbox = 'Office.Address.Mailbox',
}

export const builtInFields = {
  documentName: String(BuiltInFields.documentName),
  documentNr: String(BuiltInFields.documentNr),

  clientName: String(BuiltInFields.clientName),
  clientLogo: String(BuiltInFields.clientLogo),
  clientVat: String(BuiltInFields.clientVat),
  clientAddressStreet: String(BuiltInFields.clientAddressStreet),
  clientAddressNr: String(BuiltInFields.clientAddressNr),
  clientAddressMailbox: String(BuiltInFields.clientAddressMailbox),
  clientAddressPostalcode: String(BuiltInFields.clientAddressPostalcode),
  clientAddressCity: String(BuiltInFields.clientAddressCity),
  clientAddressCountry: String(BuiltInFields.clientAddressCountry),

  officeName: String(BuiltInFields.officeName),
  officeEmail: String(BuiltInFields.officeEmail),
  officeAddressCountry: String(BuiltInFields.officeAddressCountry),
  officeAddressPostalCode: String(BuiltInFields.officeAddressPostalCode),
  officeAddressCity: String(BuiltInFields.officeAddressCity),
  officeAddressStreet: String(BuiltInFields.officeAddressStreet),
  officeAddressHouseNumber: String(BuiltInFields.officeAddressHouseNumber),
  officeAddressMailbox: String(BuiltInFields.officeAddressMailbox),
}

export const builtInFieldsArray = (): { name: string }[] => {
  return Object.keys(BuiltInFields).map((key) => {
    return { name: BuiltInFields[key] }
  })
}
