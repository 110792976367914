import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { queryClient } from 'services/http/queryClient'
import { useAxios, ProviderValue } from 'services/http/axiosClient'
import { ResponseError } from 'services/http/types'

import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'
import { AddDocumentCmd } from 'docgen/contracts/document/AddDocumentCmd'
import { GenerateDocumentCmd } from 'docgen/contracts/document/GenerateDocumentCmd'
import { ReturnId } from 'common/models/ReturnTypes'
import { UpdateDocumentCmd } from 'docgen/contracts/document/UpdateDocumentCmd'
import { stringIsEmpty } from 'common/helpers'

export const getAllDocumentsKey = 'getAllDocuments'
export const getAllMyDocumentsKey = 'getAllMyDocuments'
export const getAllDocumentsByClientKey = 'getAllDocumentsByClient'
export const getDocumentByIdKey = 'getDocumentById'
export const addDocumentKey = 'addDocument'
export const generateDocumentKey = 'generateDocument'
export const sendDocumentKey = 'sendDocument'

// QRY
export function useGetAllDocuments() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentAggregate[], ResponseError>(getAllDocumentsKey, async () => {
    const { data } = await axiosClient.get<DocumentAggregate[]>('/document/all')
    return data
  })
}

export function useGetAllMyDocuments() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentAggregate[], ResponseError>(getAllMyDocumentsKey, async () => {
    const { data } = await axiosClient.get<DocumentAggregate[]>('/document/my')
    return data
  })
}

export function useGetAllDocumentsByClient(clientId: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentAggregate[] | undefined, ResponseError>(
    [getAllDocumentsByClientKey, clientId],
    async () => {
      if (!clientId || stringIsEmpty(clientId)) return undefined

      const { data } = await axiosClient.get<DocumentAggregate[]>(`/document?clientId=${clientId}`)
      return data
    }
  )
}

export function useGetDocumentById(id: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentAggregate | undefined, ResponseError>(
    [getDocumentByIdKey, id],
    async () => {
      if (stringIsEmpty(id)) return undefined
      const { data } = await axiosClient.get<DocumentAggregate>(`/document/${id}`)
      return data
    }
  )
}

// CMD
export function useAddDocument() {
  const axiosClient = useAxios() as ProviderValue
  const navigate = useNavigate()

  return useMutation<string, ResponseError, AddDocumentCmd, unknown>(
    addDocumentKey,
    async (addDocument: AddDocumentCmd) => {
      const { data } = await axiosClient.post<ReturnId>(`/document`, addDocument)
      return data.id
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(getAllDocumentsKey)
        navigate('/docgen/document/' + data)
      },
    }
  )
}

export function useUpdateDocument() {
  const axiosClient = useAxios() as ProviderValue
  let updatedDoc: UpdateDocumentCmd
  return useMutation<{}, ResponseError, UpdateDocumentCmd, unknown>(
    [],
    async (updateDoc: UpdateDocumentCmd) => {
      updatedDoc = updateDoc
      const { data } = await axiosClient.put(`/document`, updateDoc)
      return data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([getDocumentByIdKey, updatedDoc.id])
      },
    }
  )
}

export function useGenerateDocument(documentId: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string | undefined, ResponseError, GenerateDocumentCmd, unknown>(
    generateDocumentKey,
    async (generateDocument: GenerateDocumentCmd) => {
      if (stringIsEmpty(documentId)) return undefined
      const { data } = await axiosClient.post<ReturnId>(
        `/document/${documentId}/generate`,
        generateDocument
      )
      return data.id
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([getDocumentByIdKey, documentId])
      },
    }
  )
}

export function useDownloadDocument(documentId: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<Blob | undefined, ResponseError>([], async () => {
    if (stringIsEmpty(documentId)) return undefined
    const { data } = await axiosClient.get<Blob>(`/document/${documentId}/download`, {
      responseType: 'blob',
    })
    const blob = new Blob([data], { type: 'application/pdf' })
    return blob
  })
}

export function useSendDocument(documentId: string | undefined) {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string | undefined, ResponseError>(sendDocumentKey, async () => {
    if (stringIsEmpty(documentId)) return undefined
    const { data } = await axiosClient.post<ReturnId>(`/document/${documentId}/send`)
    return data.id
  })
}
