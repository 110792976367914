import React from 'react'
import { Link } from 'react-router-dom'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { ButtonAdd } from 'common/components'

import AllClientsGrid from 'coredata/components/clients/AllClientsGrid'

const AllClientsView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="Clients" />
        <ActionsBarContainer>
          {' '}
          <Link to={'/client/add'}>
            <ButtonAdd>New client</ButtonAdd>
          </Link>{' '}
        </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          {' '}
          <div className="col-12">
            <AllClientsGrid />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AllClientsView
