import React from 'react'
import { Link } from 'react-router-dom'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { ButtonDefault } from 'common/components'

import AllCustomersGrid from 'admindata/components/AllCustomersGrid'

const AllCustomersView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="AdminConsult Customers" />
        <ActionsBarContainer>
          {' '}
          <Link to={'/'}>
            <ButtonDefault disabled={true} icon="bi bi-arrow-clockwise">
              Refresh from AdminConsult
            </ButtonDefault>{' '}
            <span></span>
            <ButtonDefault
              disabled={true}
              icon="bi bi-cloud-upload"
              bgColor="#1d3557"
              color="white"
            >
              Sync with Portal
            </ButtonDefault>
          </Link>{' '}
        </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          {' '}
          <div className="col-12">
            <AllCustomersGrid />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AllCustomersView
