export enum DocumentStatus {
  Draft = 1,
  Generated = 2,
  SendRequested = 3,
  SendSucceeded = 4,
  SendFailed = 5,
  Signed = 6,
}

export const DocumentStatusGenerateAllowedList = [
  DocumentStatus[DocumentStatus.Draft],
  DocumentStatus[DocumentStatus.Generated],
]
export const DocumentStatusEditAllowedList = [
  DocumentStatus[DocumentStatus.Draft],
  DocumentStatus[DocumentStatus.Generated],
]
export const DocumentStatusDownloadAllowedList = [
  DocumentStatus[DocumentStatus.Generated],
  DocumentStatus[DocumentStatus.SendFailed],
  DocumentStatus[DocumentStatus.SendRequested],
  DocumentStatus[DocumentStatus.SendSucceeded],
  DocumentStatus[DocumentStatus.Signed],
]
export const DocumentStatusSendAllowedList = [
  DocumentStatus[DocumentStatus.Generated],
  DocumentStatus[DocumentStatus.SendFailed],
]
