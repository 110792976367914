import React from 'react'
import styled from 'styled-components'

const ContentContainer = ({ children }: { children?: React.ReactNode }) => {
  return <StyledContainer className="container-fluid">{children}</StyledContainer>
}

const StyledContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
  height: 100%;
`

export default ContentContainer
