export const color1: string = '#1D3558'
export const color2: string = '#457B9D !important'
export const color3: string = '#A7D9DD'
export const color4: string = '#F2FBEF'
export const colorError: string = '#E73845'
export const colorDanger: string = '#E73845'
export const colorWarning: string = '#FEAB1A'

export const colorDarkPrimary: string = '#457B9D !important'
export const colorLightPrimary: string = '#a8dadc'
export const colorLightSecondary: string = '#0182FF'

export const colorWhite: string = '#fff'
export const colorBlack: string = '#000'
export const colorGrey: string = '#c1c1c1'
export const colorBackground: string = '#f8f8f8'
