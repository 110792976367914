import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { queryClient } from 'services/http/queryClient'
import { useAxios, ProviderValue } from 'services/http/axiosClient'
import { ResponseError } from 'services/http/types'

import { OfficeAggregate } from '../models/office/OfficeAggregate'
import { RegisterNewOffice } from 'coredata/contracts/office/RegisterNewOffice'
import { stringIsEmpty } from 'common/helpers'
import { ChangeOfficeDetails } from 'coredata/contracts/office/ChangeOfficeDetails'

export const getAllOfficesKey = 'getAllOffices'
export const getOfficeByIdKey = 'getOfficeById'
export const registerOfficeKey = 'addOffice'
export const changeOfficeDetailsKey = 'changeOfficeDetails'

export function useGetAllOffices() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<OfficeAggregate[], ResponseError>(getAllOfficesKey, async () => {
    const { data } = await axiosClient.get<OfficeAggregate[]>('/office/all')
    return data
  })
}

export function useGetOfficeById(id: string) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<OfficeAggregate | undefined, ResponseError>([getOfficeByIdKey, id], async () => {
    if (stringIsEmpty(id)) return undefined
    const { data } = await axiosClient.get<OfficeAggregate>(`/office/${id}`)
    return data
  })
}

export function useRegisterNewOffice() {
  const axiosClient = useAxios() as ProviderValue
  const navigate = useNavigate()

  return useMutation<string, ResponseError, RegisterNewOffice, unknown>(
    registerOfficeKey,
    async (registerOffice: RegisterNewOffice) => {
      const { data } = await axiosClient.post<string>(`/office/register`, registerOffice)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(getAllOfficesKey)
        navigate('/offices')
      },
    }
  )
}

export function useChangeOfficeDetails() {
  const axiosClient = useAxios() as ProviderValue
  let changeOffice: ChangeOfficeDetails
  return useMutation<{}, ResponseError, ChangeOfficeDetails, unknown>(
    [],
    async (changeOfficeDetails: ChangeOfficeDetails) => {
      changeOffice = changeOfficeDetails
      const { data } = await axiosClient.put(`/office/details`, changeOfficeDetails)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([getOfficeByIdKey, changeOffice.id])
      },
    }
  )
}
