import React from 'react'

import { ContentContainer, TitleContainer } from 'common/layout'
import {
  GetLoginRedirectUri,
  GetMsalCacheLocation,
  GetMsalClientId,
  GetMsalClientScope,
  GetMsalTenantAuthorityUri,
  GetWebApiBaseUri,
  GetWebAppCustomNodeEnv,
  GetWebAppName,
  GetWebAppVersion,
} from 'services/appsettings'
import { GetLoggedInUser } from 'services/auth/authService'

const ConfigView: React.FC = () => {
  return (
    <>
      <TitleContainer title="Configuration" />

      <ContentContainer>
        <div className="row">
          <div className="col-12">
            <b>NODE_ENV:</b> {process.env.NODE_ENV} <br />
            <b>REACT_APP_NODE_ENV:</b> {GetWebAppCustomNodeEnv()} <br />
            <br />
            <b>WEB APP NAME:</b> {GetWebAppName()} <br />
            <b>WEB APP VERSION:</b> {GetWebAppVersion()} <br />
            <br />
            <b>WEB API BASEURI:</b> {GetWebApiBaseUri()} <br />
            <br />
            <b>MSAL TENANT AUTHORITY URI:</b> {GetMsalTenantAuthorityUri()} <br />
            <b>MSAL LOGIN REDIRECT URI:</b> {GetLoginRedirectUri()} <br />
            <b>MSAL CACHE LOCATION:</b> {GetMsalCacheLocation()} <br />
            <b>MSAL CLIENT ID:</b> {GetMsalClientId()} <br />
            <b>MSAL CLIENT SCOPE:</b> {GetMsalClientScope()} <br />
            <br />
            <b>LOGGED IN USER:</b> {GetLoggedInUser().username} <br />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default ConfigView
