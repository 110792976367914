import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  IFilter,
  Inject,
  Sort,
  Toolbar,
  Page,
  RecordClickEventArgs,
} from '@syncfusion/ej2-react-grids'

import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'

import { useGetAllOffices } from 'coredata/hooks/useOffices'
import { ErrorContainer } from 'common/components'
import { OfficeAggregate } from 'coredata/models/office/OfficeAggregate'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllClientsGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  const allOfficesData = useGetAllOffices()
  let toolbarOptions: any = ['Search']

  let check: IFilter = {
    type: 'CheckBox',
  }

  function recordClicked(args: RecordClickEventArgs) {
    const clickedOfficeRecord = args.rowData as OfficeAggregate
    navigate('/office/' + clickedOfficeRecord.id)
  }

  return (
    <>
      {allOfficesData.isFetching && <Loading />}
      {allOfficesData.error && <ErrorContainer error={allOfficesData.error} />}
      {!allOfficesData.isFetching && (
        <StyledGrid
          dataSource={allOfficesData.data}
          allowPaging={true}
          allowSorting={true}
          pageSettings={{ pageSize: 15, pageCount: 5 }}
          toolbar={toolbarOptions}
          enableHover={true}
          enableHeaderFocus={true}
          locale={locale}
          allowSelection={true}
          recordClick={recordClicked.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Office Nr"
              field="externalId"
              width="75"
              textAlign="Left"
            />
            <ColumnDirective
              headerText="Name"
              field="name"
              width="200"
              textAlign="Left"
              filter={check}
            />
            <ColumnDirective
              headerText="Email"
              field="email.value"
              width="100"
              textAlign="Left"
              filter={check}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Toolbar, Page]} />
        </StyledGrid>
      )}
    </>
  )
}

export default AllClientsGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }

  cursor: pointer;
`
