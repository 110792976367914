import React from 'react'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import AddOfficeForm from 'coredata/components/offices/AddOfficeForm'

const AddOfficeView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="" />
        <ActionsBarContainer> </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          <div className="col-8">
            <AddOfficeForm />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AddOfficeView
