import React from 'react'
import styled from 'styled-components'

interface Props {
  children?: React.ReactNode
  width?: string
}

const ActionsBarContainer = (props: Props) => {
  return (
    <StyledDiv width={props.width} className="container-fluid">
      {props.children}
    </StyledDiv>
  )
}

const StyledDiv = styled.div<Props>`
  padding: var(--bs-gutter-x, 0.75rem);
  ${(props) => (props.width ? 'width: ' + props.width + '%;' : 'width: 50%;')};
  float: right;
  height: 80px;
  text-align: right;
`

export default ActionsBarContainer
