import styled from 'styled-components'

export const Loading = () => {
  return (
    <div className="spinner-border text-info" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  )
}

export const LoadingSmall = () => {
  return (
    <SmallDiv className="spinner-border text-info" role="status">
      <span className="visually-hidden">.</span>
    </SmallDiv>
  )
}

const SmallDiv = styled.div`
  width: 1rem;
  height: 1rem;
`
