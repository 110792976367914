import React from 'react'
import styled from 'styled-components'

export const BoxContainer = ({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) => {
  return (
    <div className="card">
      <h5 className="card-header">{title}</h5>
      <StyledContainer className="card-body">{children}</StyledContainer>
    </div>
  )
}

const StyledContainer = styled.div`
  font-size: 12px;
`
