import React from 'react'
import styled from 'styled-components'

interface IAuthFailureProps {
  errorMessage: string
}

class AuthFailure extends React.Component<IAuthFailureProps, {}> {
  render() {
    return (
      <AuthFailureContainer>
        <p>{this.props.errorMessage}</p>
      </AuthFailureContainer>
    )
  }
}

export default AuthFailure

const AuthFailureContainer = styled.div``
