import { AccountInfo, AuthenticationResult, RedirectRequest } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import { GetMsalClientScope } from 'services/appsettings'
import { IDTokenClaims } from './authModels'

export async function GetToken(): Promise<AuthenticationResult> {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const loginHint = account?.username
  let token = {} as AuthenticationResult

  let tokenRequest = {
    account: account as AccountInfo,
    loginHint: loginHint,
    scopes: [GetMsalClientScope()],
  } as RedirectRequest

  try {
    token = await instance.ssoSilent(tokenRequest)
  } catch (error) {
    token = await instance.acquireTokenSilent(tokenRequest)
    // msal will return the cached token if present, or call to get a new one if it is expired or near expiring.
  }
  return token
}

export function GetLoggedInUser(): AccountInfo {
  const { accounts } = useMsal()
  return accounts[0] && accounts[0]
}

export function GetLoggedInUserFullName(): string {
  const { accounts } = useMsal()
  return accounts[0].name ? accounts[0].name : 'User'
}

export async function GetLoggedInUserClaims(): Promise<IDTokenClaims> {
  const claims = await GetToken().then((res) => {
    return res.idTokenClaims as IDTokenClaims
  })
  return claims
}
