import { useMutation, useQuery } from 'react-query'
import { useAxios, ProviderValue } from 'services/http/axiosClient'
import { ResponseError } from 'services/http/types'
import { ClientAggregate } from '../models/client/ClientAggregate'
import { RegisterNewClient } from 'coredata/contracts/client/RegisterNewClient'
import { AddNewClientContact } from 'coredata/contracts/client/AddNewClientContact'
import { ReturnId } from 'common/models/ReturnTypes'
import { ChangeClientContact } from 'coredata/contracts/client/ChangeClientContact'
import { ChangeClientDetails } from 'coredata/contracts/client/ChangeClientDetails'
import { queryClient } from 'services/http/queryClient'
import { stringIsEmpty } from 'common/helpers'

export const getAllClientsKey = 'getAllClients'
export const getClientByIdKey = 'getClientById'
export const registerClientKey = 'addClient'

//QRY
export function useGetAllClients() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<ClientAggregate[], ResponseError>(getAllClientsKey, async () => {
    const { data } = await axiosClient.get<ClientAggregate[]>('/client/all')
    return data
  })
}

export function useGetClientById(id: string) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<ClientAggregate | undefined, ResponseError>([getClientByIdKey, id], async () => {
    if (stringIsEmpty(id)) return undefined
    const { data } = await axiosClient.get<ClientAggregate>(`/client/${id}`)
    return data
  })
}

// CMD
export function useRegisterNewClient() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string, ResponseError, RegisterNewClient, unknown>(
    registerClientKey,
    async (registerClient: RegisterNewClient) => {
      const { data } = await axiosClient.post<ReturnId>(`/client/register`, registerClient)
      return data.id
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(getAllClientsKey)
      },
    }
  )
}

export function useChangeClientDetails() {
  const axiosClient = useAxios() as ProviderValue
  let changeClient: ChangeClientDetails
  return useMutation<{}, ResponseError, ChangeClientDetails, unknown>(
    [],
    async (changeClientDetails: ChangeClientDetails) => {
      changeClient = changeClientDetails
      const { data } = await axiosClient.put(`/client/details`, changeClientDetails)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([getClientByIdKey, changeClient.clientId])
      },
    }
  )
}

// ClientContacts
export function useAddNewClientContact() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string, ResponseError, AddNewClientContact, unknown>(
    [],
    async (addNewClientContact: AddNewClientContact) => {
      const { data } = await axiosClient.post<ReturnId>(`/client/contact`, addNewClientContact)
      return data.id
    }
  )
}

export function useChangeClientContact() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<{}, ResponseError, ChangeClientContact, unknown>(
    [],
    async (changeClientContact: ChangeClientContact) => {
      const { data } = await axiosClient.put(`/client/contact`, changeClientContact)
      return data
    }
  )
}
