import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { Loading, BoxContainer, ErrorContainer } from 'common/components'

import * as stylesConfig from 'common/styles/stylesConfig'

import { DocumentType, DocumentTypeArray } from 'docgen/models/DocumentType'
import { AddDocumentTemplateCmd } from 'docgen/contracts/documenttemplate/AddDocumentTemplateCmd'
import { useAddDocumentTemplate } from 'docgen/hooks/useDocumentTemplates'
import { GetLoggedInUser } from 'services/auth/authService'
import { Placeholders } from 'docgen/models/documenttemplate/Placeholders'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AddDocumentTemplateForm = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  const account = GetLoggedInUser()
  const addDocumentTemplate = useAddDocumentTemplate()

  const [name, setName] = useState('')
  const [language, setLanguage] = useState('')
  const [documentUrl, setDocumentUrl] = useState('')
  const types = DocumentTypeArray()
  const [type, setType] = useState({} as DocumentType)

  function cancel() {
    navigate('/docgen/documenttemplates')
  }
  function save() {
    try {
      const addDocumentTemplateCmd = {
        name: name,
        documentType: type,
        language: language,
        documentUrl: documentUrl,
      } as AddDocumentTemplateCmd

      addDocumentTemplate.mutate(addDocumentTemplateCmd)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <BoxContainer title="Add new document template">
      {addDocumentTemplate.isSuccess && (
        <Navigate to={'/docgen/documenttemplate/' + addDocumentTemplate.data} />
      )}
      {addDocumentTemplate.isLoading && <Loading />}
      {addDocumentTemplate.error && <ErrorContainer error={addDocumentTemplate.error} />}
      {!addDocumentTemplate.isLoading && (
        <div>
          <b>Document Template Info</b>
          <TextBoxComponent
            key="name"
            change={(event) => setName(event.value as string)}
            placeholder="Template Name *"
            floatLabelType="Auto"
            disabled={addDocumentTemplate.isLoading}
          />
          <StyledDdlContainer>
            <DropDownListComponent
              id="ddlType"
              key="type"
              dataSource={types}
              placeholder="Select a Type"
              floatLabelType="Auto"
              disabled={addDocumentTemplate.isLoading}
              change={(event) => setType(event.value as DocumentType)}
            />
          </StyledDdlContainer>
          <StyledDdlContainer>
            <DropDownListComponent
              id="ddlLanguage"
              key="language"
              dataSource={['NL', 'FR', 'EN']}
              placeholder="Select a Language"
              floatLabelType="Auto"
              disabled={addDocumentTemplate.isLoading}
              change={(event) => setLanguage(event.value)}
            />
          </StyledDdlContainer>
          <TextBoxComponent
            key="documenturl"
            change={(event) => setDocumentUrl(event.value as string)}
            placeholder="Document location on SharePoint *"
            floatLabelType="Auto"
            disabled={addDocumentTemplate.isLoading}
          />

          <StyledValidateButtonsdiv>
            <StyledActionButton onClick={cancel}>Cancel</StyledActionButton>
            <StyledActionButton onClick={save}>Save</StyledActionButton>
          </StyledValidateButtonsdiv>
        </div>
      )}
    </BoxContainer>
  )
}

export default AddDocumentTemplateForm

const StyledDdlContainer = styled.div``

const StyledValidateButtonsdiv = styled.div`
  margin: 35px 0;
  text-align: right;
`

const StyledActionButton = styled(ButtonComponent)`
  margin-left: 35px;
  width: 100px;
`
