import React from 'react'
import { Link } from 'react-router-dom'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { ButtonAdd } from 'common/components/Buttons'

import AllOfficesGrid from 'coredata/components/offices/AllOfficesGrid'

const AllOfficesView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="Offices" />
        <ActionsBarContainer>
          <Link to={'/office/add'}>
            <ButtonAdd>New office</ButtonAdd>{' '}
          </Link>
        </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          <div className="col-12">
            <AllOfficesGrid />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AllOfficesView
