/* -- App -- */
export function GetWebAppCustomNodeEnv(): string {
  return process.env.REACT_APP_NODE_ENV as string
}
export function GetWebAppVersion(): string {
  return process.env.REACT_APP_VERSION as string
}
export function GetWebAppName(): string {
  return process.env.REACT_APP_TITLE as string
}
export function GetWebApiBaseUri(): string {
  return process.env.REACT_APP_WEB_API_BASE_URI as string
}

export function GetMyProfileUri(): string {
  return 'https://metiselectcloud-my.sharepoint.com/person.aspx'
}

/* -- MSAL -- */
export function GetMsalClientId(): string {
  return process.env.REACT_APP_MSAL_CLIENT_ID as string
}
export function GetMsalClientScope(): string {
  return process.env.REACT_APP_MSAL_CLIENT_SCOPE as string
}
export function GetMsalTenantAuthorityUri(): string {
  return process.env.REACT_APP_MSAL_TENANT_AUTHORITY_URI as string
}
export function GetMsalCacheLocation(): string {
  return process.env.REACT_APP_MSAL_CACHE_LOCATION as string
}
export function GetMsalStoreAuthInCookie(): boolean {
  let stringValue = process.env.REACT_APP_MSAL_AUTH_STATE_IN_COOKIE as string

  if (stringValue.toLowerCase() === 'true') {
    return true
  } else if (stringValue.toLowerCase() === 'false') {
    return false
  } else {
    throw new Error('MSAL_AUTH_STATE_IN_COOKIE setting is not a valid boolean.')
  }
}
export function GetLoginRedirectUri(): string {
  return process.env.REACT_APP_MSAL_LOGIN_REDIRECT_URI as string
}
