import React from 'react'
import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import AddClientForm from 'coredata/components/clients/AddClientForm'

const AddClientView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="" />
        <ActionsBarContainer> </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          <div className="col-8">
            <AddClientForm />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AddClientView
