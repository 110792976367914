import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { Loading, BoxContainer, ErrorContainer } from 'common/components'

import * as stylesConfig from 'common/styles/stylesConfig'

import { RegisterNewClient } from 'coredata/contracts/client/RegisterNewClient'
import { useRegisterNewClient } from 'coredata/hooks/useClients'
import { GetLoggedInUser } from 'services/auth/authService'
import { useGetAllOffices } from 'coredata/hooks/useOffices'
L10n.load(SYNCFUSIONTRANSLATIONS)

const AddClientForm = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  const addClient = useRegisterNewClient()

  const [name, setName] = useState('')
  const [vat, setVat] = useState('')
  const [email, setEmail] = useState('')
  const [officeId, setOfficeId] = useState('')

  const allOffices = useGetAllOffices()

  const account = GetLoggedInUser()

  function cancel() {
    navigate('/clients')
  }
  function save() {
    try {
      const registerNewClient = {
        name: name,
        officeId: officeId,
        email: email,
        vatNumber: vat,
      } as RegisterNewClient

      addClient.mutate(registerNewClient)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {addClient.isSuccess && navigate('/client/' + addClient.data)}
      {addClient.error && <ErrorContainer error={addClient.error} />}
      <BoxContainer title="Add new client">
        <div>
          <b>Client Info</b>
          <TextBoxComponent
            key="name"
            change={(event) => setName(event.value as string)}
            placeholder="Company Name *"
            floatLabelType="Auto"
            disabled={addClient.isLoading}
          />

          <StyledDdlContainer>
            <DropDownListComponent
              id="ddlOffices"
              key="offices"
              dataSource={
                allOffices.data
                  ? allOffices.data.map((o, key) => {
                      return { key: o }
                    })
                  : undefined
              }
              fields={{ text: 'key.name', value: 'key.id' }}
              placeholder="Main Office *"
              disabled={addClient.isLoading}
              change={(event) => {
                setOfficeId(event.value as string)
              }}
            />
          </StyledDdlContainer>
          <TextBoxComponent
            key="vat"
            change={(event) => setVat(event.value as string)}
            placeholder="VAT Number"
            floatLabelType="Auto"
            disabled={addClient.isLoading}
          />
          <TextBoxComponent
            key="email"
            change={(event) => setEmail(event.value as string)}
            placeholder="Email address *"
            floatLabelType="Auto"
            disabled={addClient.isLoading}
          />
        </div>
        {addClient.isLoading && <Loading />}

        {!addClient.isLoading && (
          <StyledValidateButtonsdiv>
            <StyledActionButton onClick={cancel}>Cancel</StyledActionButton>
            <StyledActionButton onClick={save}>Save</StyledActionButton>
          </StyledValidateButtonsdiv>
        )}
      </BoxContainer>
    </>
  )
}

export default AddClientForm

const StyledDdlContainer = styled.div`
  margin-top: 25px;
`

const StyledValidateButtonsdiv = styled.div`
  margin: 35px 0;
  text-align: right;
`

const StyledActionButton = styled(ButtonComponent)`
  margin-left: 35px;
  width: 100px;
`
