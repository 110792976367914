import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ActionEventArgs,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  Filter,
  GridComponent,
  IFilter,
  Inject,
  Page,
  RecordClickEventArgs,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { Loading, ErrorContainer, BoxContainer, ButtonEdit, ButtonDelete } from 'common/components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import {
  useAddNewClientContact,
  useChangeClientContact,
  useChangeClientDetails,
  useGetClientById,
} from 'coredata/hooks/useClients'
import styled from 'styled-components'
import { useGetAllDocumentsByClient } from 'docgen/hooks/useDocuments'
import { DocumentStatusBadge } from 'docgen/components/document/DocumentStatusBadge'
import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'
import { getResponseErrorMessage } from 'services/http/utils'
import { AddNewClientContact } from 'coredata/contracts/client/AddNewClientContact'
import { ClientContactInformation } from 'coredata/models/client/ClientAggregate'
import { GetLoggedInUser } from 'services/auth/authService'
import { VerifyPermissions } from 'services/auth/authorizationService'
import { ChangeClientContact } from 'coredata/contracts/client/ChangeClientContact'
import {
  DialogComponent,
  ButtonPropsModel,
  AnimationSettingsModel,
} from '@syncfusion/ej2-react-popups'
import {
  SelectedEventArgs,
  TextBoxComponent,
  UploaderComponent,
} from '@syncfusion/ej2-react-inputs'
import { DocumentTypeText } from 'docgen/components/document/DocumentTypeText'
import { useGetAllOffices, useGetOfficeById } from 'coredata/hooks/useOffices'
import { AvatarBase64 } from 'common/components/AvatarBase64'
import * as helpers from 'common/helpers'
import { ChangeClientDetails } from 'coredata/contracts/client/ChangeClientDetails'
import { Address } from 'coredata/models/BaseTypes'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { UserRoles } from 'services/auth/userRoles'

L10n.load(SYNCFUSIONTRANSLATIONS)

const ClientView = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  let { clientId } = useParams()
  const account = GetLoggedInUser()

  // Global
  const clientData = useGetClientById(clientId || '')
  const allOfficesData = useGetAllOffices()
  const officeData = useGetOfficeById(clientData.data?.officeId || '')

  const tabHeaders = [{ text: 'Documents' }, { text: 'Evaluations' }]
  let check: IFilter = {
    type: 'CheckBox',
  }

  // Edit Details
  const changeClientDetails = useChangeClientDetails()
  let promptDialogInstance = useRef<DialogComponent>(null)
  let editClientButtons: ButtonPropsModel[]
  let animationSettings: AnimationSettingsModel
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [changeOfficeId, setChangeOfficeId] = useState('')
  let nameInput: TextBoxComponent | null
  let emailInput: TextBoxComponent | null
  let clientNrInput: TextBoxComponent | null
  let vatInput: TextBoxComponent | null
  let aStreetInput: TextBoxComponent | null
  let aNrInput: TextBoxComponent | null
  let aMailboxInput: TextBoxComponent | null
  let aZipInput: TextBoxComponent | null
  let aCityInput: TextBoxComponent | null
  let aCountryCodeInput: TextBoxComponent | null
  editClientButtons = [
    {
      click: () => {
        const changedDetails = {
          clientId: clientId,
          externalId: clientNrInput?.value,
          officeId: changeOfficeId,
          name: nameInput?.value,
          email: emailInput?.value,
          vatNumber: vatInput?.value,
          logo64: clientData.data?.logo64,
          address: {
            countryIso2: aCountryCodeInput?.value,
            zipCode: aZipInput?.value,
            city: aCityInput?.value,
            street: aStreetInput?.value,
            number: aNrInput?.value,
            mailbox: aMailboxInput?.value,
          } as Address,
        } as ChangeClientDetails

        changeClientDetails.mutate(changedDetails)
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Save', isPrimary: true },
    },
    {
      click: () => {
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Cancel' },
    },
  ]
  animationSettings = { effect: 'None' }
  function btnEditClientClick() {
    setEditDialogOpen(true)
  }
  function editClientDialogClose() {
    setEditDialogOpen(false)
  }

  function checkIfAdmin() {}

  // Upload Logo
  const [uploadLogoDialogOpen, setUploadLogoDialogOpen] = useState(false)
  const [uploadedBase64, setUploadedBase64] = useState('')

  let promptUploadLogoDialogInstance = useRef<DialogComponent>(null)
  let uploadLogoButtons: ButtonPropsModel[]
  uploadLogoButtons = [
    {
      click: () => {
        console.log(uploadedBase64)

        const changedLogoDetails = {
          clientId: clientId,
          externalId: clientData.data?.externalId,
          name: clientData.data?.name,
          email: clientData.data?.email.value,
          vatNumber: clientData.data?.vatNumber,
          logo64: uploadedBase64,
          officeId: clientData.data?.officeId,
          address: clientData.data?.address,
        } as ChangeClientDetails

        changeClientDetails.mutate(changedLogoDetails)

        setUploadLogoDialogOpen(false)
      },
      buttonModel: { content: 'Save', isPrimary: true },
    },
    {
      click: () => {
        setUploadLogoDialogOpen(false)
      },
      buttonModel: { content: 'Cancel' },
    },
  ]

  function logoUploadCreateBase64(args: SelectedEventArgs) {
    let blob = args.filesData[0].rawFile as Blob
    let base64data
    let reader = new window.FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      base64data = reader.result
      setUploadedBase64(helpers.formatBase64String(base64data))
    }
  }
  function btnUploadLogoClick() {
    setUploadLogoDialogOpen(true)
  }
  function uploadLogoDialogClose() {
    setUploadLogoDialogOpen(false)
  }
  let logoUploadComponent = useRef<UploaderComponent>(null)

  // Contacts
  const addNewClientContact = useAddNewClientContact()
  const changeClientContact = useChangeClientContact()
  const toolbarOptionsContacts: any = ['Add', 'Edit']
  const editSettingsContacts: any = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Dialog',
  }
  const validRequired = { required: true }
  const validEmailRequired = { required: true, email: true }

  function actionCompletedContacts(args: ActionEventArgs) {
    console.log(args)

    if (args.action === 'add' && args.type === 'actionComplete') {
      try {
        if (args.data) {
          const data = args.data as ClientContactInformation
          const addNewClientContactData = {
            clientId: clientId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email?.value,
          } as AddNewClientContact

          addNewClientContact.mutate(addNewClientContactData)
        }
      } catch (err) {
        console.error(err)
      }
    }
    if (args.action === 'edit' && args.type === 'actionComplete') {
      try {
        if (args.data) {
          const data = args.data as ClientContactInformation
          const changeClientContactData = {
            clientId: clientId,
            contactId: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email?.value,
          } as ChangeClientContact

          changeClientContact.mutate(changeClientContactData)
        }
      } catch (err) {
        console.error(err)
      }
    }
    if (args.action === 'delete' && args.type === 'actionComplete') {
      try {
        if (args.data) {
          /*
          const changeClientContactData = {
            clientId: clientId,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email?.value,
          } as ChangeClientContact

          changeClientContact.mutate(changeClientContactData)*/
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  // Documents
  const clientDocumentsData = useGetAllDocumentsByClient(clientId)
  function documentRowClicked(args: RecordClickEventArgs) {
    const clickedDocumentRecord = args.rowData as DocumentAggregate
    navigate('/docgen/document/' + clickedDocumentRecord.id)
  }
  const createdOnTemplate = (data: DocumentAggregate) => {
    return helpers.formatDate(data.auditData?.createdOn)
  }
  const createdByTemplate = (data: DocumentAggregate) => {
    let name = data.auditData?.createdBy
      ? helpers.usernameToName(data.auditData?.createdBy)
      : data.auditData?.createdBy
    return name
  }

  return (
    <>
      {clientData.isFetching && <Loading />}
      {clientData.error && <ErrorContainer error={clientData.error} />}
      {changeClientDetails.error && <ErrorContainer error={changeClientDetails.error} />}
      {!clientData.isFetching && !clientData.error && (
        <>
          <div className="container-fluid">
            <TitleContainer title={clientData.data?.name || 'Client not found'} />
            <ActionsBarContainer>
              <ButtonEdit onClick={btnEditClientClick}></ButtonEdit>{' '}
              <ButtonDelete
                disabled={true}
                onClick={() => alert('Are you sure you want to delete the client?')}
              ></ButtonDelete>{' '}
            </ActionsBarContainer>
          </div>
          <ContentContainer>
            <div className="row" id="dialogTarget">
              <div className="col-6">
                <BoxContainer title="Client information">
                  <StyledLogoContainer>
                    <StyledLogoLink onClick={btnUploadLogoClick}>
                      <StyledLogoOverlay className="overlay">
                        <StyledLogoIcon className="bi bi-pencil-fill"></StyledLogoIcon>
                      </StyledLogoOverlay>

                      <AvatarBase64
                        imageBase64={clientData.data?.logo64}
                        initials={helpers.toInitials(clientData.data?.name)}
                      />
                    </StyledLogoLink>
                  </StyledLogoContainer>
                  <StyledDetailsContainer>
                    Client Nr: <b>{clientData.data?.externalId}</b>
                    <br />
                    MetiSelect Office: <b>{officeData.data?.name}</b>
                    <br />
                    Email: <b>{clientData.data?.email?.value}</b>
                    <br />
                    VAT: <b>{clientData.data?.vatNumber}</b>
                    <br />
                    <br />
                    Address: <br />
                    <b>
                      {clientData.data?.address?.street} {clientData.data?.address?.number}{' '}
                      {clientData.data?.address?.mailbox
                        ? '- ' + clientData.data?.address?.mailbox
                        : ''}
                    </b>
                    <br />
                    <b>
                      {clientData.data?.address?.zipCode} {clientData.data?.address?.city}{' '}
                      {clientData.data?.address?.countryIso2}
                    </b>
                    <br />
                  </StyledDetailsContainer>
                </BoxContainer>
              </div>
              <div className="col-6">
                <BoxContainer title="Contacts">
                  <StyledGrid
                    dataSource={clientData.data?.contacts}
                    allowPaging={true}
                    allowSorting={true}
                    pageSettings={{ pageSize: 5, pageCount: 5 }}
                    enableHeaderFocus={true}
                    locale={locale}
                    toolbar={toolbarOptionsContacts}
                    editSettings={editSettingsContacts}
                    actionComplete={actionCompletedContacts}
                  >
                    <ColumnsDirective>
                      <ColumnDirective headerText="ID" field="id" visible={false} />
                      <ColumnDirective
                        headerText="Firstname"
                        field="firstName"
                        width="100"
                        textAlign="Left"
                        validationRules={validRequired}
                      />
                      <ColumnDirective
                        headerText="Lastname"
                        field="lastName"
                        width="100"
                        textAlign="Left"
                        validationRules={validRequired}
                      />
                      <ColumnDirective
                        headerText="Email"
                        field="email.value"
                        width="100"
                        textAlign="Left"
                        validationRules={validEmailRequired}
                      />
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Toolbar, Edit]} />
                  </StyledGrid>
                </BoxContainer>
              </div>
              <div className="col-12">
                <br />
                {contentDocuments()}
              </div>
            </div>
          </ContentContainer>
          <StyledDialogComponent
            id="promptDialog"
            header={'Edit details of ' + clientData?.data?.name}
            visible={editDialogOpen}
            showCloseIcon={true}
            animationSettings={animationSettings}
            width="auto"
            height="auto"
            ref={promptDialogInstance}
            target="#dialogTarget"
            buttons={editClientButtons}
            close={editClientDialogClose.bind(this)}
            allowDragging={true}
          >
            {/* Prompt Dialog content  */}
            <table className="e-table" cellSpacing="6px">
              <tbody>
                <tr>
                  <td>
                    <strong>Info:</strong>
                  </td>
                </tr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2} width="400">
                    <TextBoxComponent
                      type="text"
                      placeholder="Name"
                      value={clientData.data?.name}
                      floatLabelType="Always"
                      ref={(n) => (nameInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Client Nr"
                      value={clientData.data?.externalId}
                      floatLabelType="Always"
                      readOnly={!VerifyPermissions(UserRoles.portalAdmin)}
                      ref={(n) => (clientNrInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="Email"
                      value={clientData.data?.email.value}
                      floatLabelType="Always"
                      ref={(n) => (emailInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="VAT"
                      value={clientData.data?.vatNumber}
                      floatLabelType="Always"
                      ref={(n) => (vatInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <tr>
                  <td>
                    <strong>Office:</strong>
                  </td>
                </tr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell">
                    <DropDownListComponent
                      id="ddlOffices"
                      key="offices"
                      dataSource={
                        allOfficesData.data
                          ? allOfficesData.data.map((o, key) => {
                              return { key: o }
                            })
                          : undefined
                      }
                      fields={{ text: 'key.name', value: 'key.id' }}
                      value={officeData.data?.id}
                      change={(event) => {
                        setChangeOfficeId(event.value as string)
                      }}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <tr>
                  <td>
                    <strong>Address:</strong>
                  </td>
                </tr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="Street"
                      value={clientData.data?.address?.street}
                      floatLabelType="Always"
                      ref={(n) => (aStreetInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Nr"
                      value={clientData.data?.address?.number}
                      floatLabelType="Always"
                      ref={(n) => (aNrInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Mailbox"
                      value={clientData.data?.address?.mailbox}
                      floatLabelType="Always"
                      ref={(n) => (aMailboxInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="City"
                      value={clientData.data?.address?.city}
                      floatLabelType="Always"
                      ref={(n) => (aCityInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="number"
                      placeholder="Zip"
                      value={clientData.data?.address?.zipCode}
                      floatLabelType="Always"
                      ref={(n) => (aZipInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>

                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="CountryCode"
                      value={clientData.data?.address?.countryIso2}
                      floatLabelType="Always"
                      ref={(n) => (aCountryCodeInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
              </tbody>
            </table>
          </StyledDialogComponent>

          <StyledDialogComponent
            id="promptDialog"
            header={'Change logo of ' + clientData?.data?.name}
            visible={uploadLogoDialogOpen}
            showCloseIcon={true}
            animationSettings={animationSettings}
            width="auto"
            height="auto"
            ref={promptUploadLogoDialogInstance}
            target="#dialogTarget"
            buttons={uploadLogoButtons}
            close={uploadLogoDialogClose.bind(this)}
            allowDragging={true}
          >
            {/* Prompt Dialog content  */}
            <ImageUploadTable className="e-table" cellSpacing="6px">
              <tbody>
                <tr>
                  <td className="e-rowcell" colSpan={12}>
                    <UploaderComponent
                      id="fileUpload"
                      type="file"
                      ref={logoUploadComponent}
                      autoUpload={false}
                      allowedExtensions={'.jpg,.png,.jpeg'}
                      maxFileSize={500000}
                      selected={logoUploadCreateBase64}
                      multiple={false}
                      buttons={undefined}
                    ></UploaderComponent>
                  </td>
                  <td
                    className="e-rowcell"
                    colSpan={12}
                    style={{ paddingTop: '5px', paddingLeft: '15px' }}
                  >
                    {uploadedBase64 && (
                      <AvatarBase64 imageBase64={uploadedBase64} width="150px" height="150px" />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="e-rowcell" colSpan={12}>
                    * File extension has to be: .jpg, .jpeg or .png <br />* File size has be smaller
                    than 0.5mb <br />* Image width has to be smaller than 250px
                  </td>
                </tr>
              </tbody>
            </ImageUploadTable>
          </StyledDialogComponent>
        </>
      )}
    </>
  )

  function contentDocuments(this: any) {
    return (
      <>
        {clientDocumentsData.isFetching && <Loading />}
        {clientDocumentsData.error && <p>{getResponseErrorMessage(clientDocumentsData.error)}</p>}
        {!clientDocumentsData.isFetching && (
          <StyledTabContent>
            <BoxContainer title="Documents">
              <StyledGrid
                dataSource={clientDocumentsData.data}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                allowSelection={false}
                recordClick={documentRowClicked.bind(this)}
                filterSettings={{
                  type: 'Menu',
                }}
                sortSettings={{ columns: [{ field: 'number', direction: 'Descending' }] }}
                pageSettings={{ pageSize: 15, pageCount: 5 }}
                enableHeaderFocus={true}
                locale={locale}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    headerText="Nr"
                    field="number"
                    width="75"
                    textAlign="Left"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    headerText="Document Name"
                    field="name"
                    width="200"
                    textAlign="Left"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    headerText="Contact"
                    field="recipient.name"
                    width="200"
                    textAlign="Left"
                    filter={check}
                  />
                  <ColumnDirective
                    headerText="Type"
                    field="type"
                    width="100"
                    textAlign="Left"
                    filter={check}
                    template={DocumentTypeText}
                  />
                  <ColumnDirective
                    headerText="Status"
                    field="status"
                    width="100"
                    textAlign="Left"
                    template={DocumentStatusBadge}
                    filter={check}
                  />
                  <ColumnDirective
                    headerText="Created On"
                    field="auditData.createdOn"
                    template={createdOnTemplate}
                    width="100"
                    textAlign="Left"
                    allowFiltering={false}
                  />
                  <ColumnDirective
                    headerText="Created By"
                    field="auditData.createdBy"
                    template={createdByTemplate}
                    filterTemplate={createdByTemplate}
                    width="100"
                    textAlign="Left"
                    filter={check}
                  />
                </ColumnsDirective>
                <Inject services={[Sort, Filter, Page]} />
              </StyledGrid>
            </BoxContainer>
          </StyledTabContent>
        )}
      </>
    )
  }

  function contentEvaluations() {
    return <StyledTabContent></StyledTabContent>
  }
}

export default ClientView

const StyledGrid = styled(GridComponent)`
  cursor: pointer;
`

const StyledLogoContainer = styled.div`
  float: left;
  margin-right: 20px;
  display: inline-block !important;
  position: relative !important;
  overflow: hidden;
`
const StyledLogoOverlay = styled.div`
  display: none;
`
const StyledLogoLink = styled.a`
  :hover .overlay {
    display: inline;
    text-align: center;
    position: absolute;

    transition: background 0.2s ease, padding 0.8s linear;
    background-color: rgba(58, 114, 171, 0.48);
    color: #fff;
    width: 100%;
    height: 100%;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    z-index: 9;
    cursor: pointer;
  }
`
const StyledDetailsContainer = styled.div`
  float: left;
`
const StyledLogoIcon = styled.i`
  position: relative;
  top: 30%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`

const ImageUploadTable = styled.table`
  .e-upload-actions {
    display: none !important;
  }
`

const StyledTabContent = styled.div`
  padding: 15px 0 40px 0;
`
const StyledDialogComponent = styled(DialogComponent)`
  max-height: unset !important;
  top: 20px !important;
  height: auto !important;
  width: auto !important;
  min-width: 450px !important;
  min-height: 300px !important;
`
const StyledDialogTr = styled.tr``
const StyledDialogTd = styled.td`
  padding: 5px 50px 20px 0;
`
