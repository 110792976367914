import React from 'react'
import { ResponseError } from 'services/http/types'
import { getResponseErrorMessage, getResponseErrorTitle } from 'services/http/utils'
import styled from 'styled-components'

export const ErrorContainer = ({ title, error }: { title?: string; error: ResponseError }) => {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">{title} Error</h4>

      <p>
        <strong>{getResponseErrorTitle(error)}: </strong> {getResponseErrorMessage(error)}
      </p>
    </div>
  )
}

export const CustomErrorContainer = ({
  errorTitle,
  errorMessages,
}: {
  errorTitle: string
  errorMessages: string[]
}) => {
  return (
    <div className="alert alert-danger" role="alert">
      <h4 className="alert-heading">{errorTitle}</h4>
      {errorMessages.map((e) => (
        <p>{e}</p>
      ))}
    </div>
  )
}

const StyledContainer = styled.div`
  margin: 25px;
`
