import React from 'react'
import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'

import AddDocumentTemplateForm from 'docgen/components/documenttemplate/AddDocumentTemplateForm'

const AddDocumentTemplateView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="" />
        <ActionsBarContainer> </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          <div className="col-8">
            <AddDocumentTemplateForm />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AddDocumentTemplateView
