import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  IFilter,
  Inject,
  Sort,
  Page,
  RecordClickEventArgs,
} from '@syncfusion/ej2-react-grids'

import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'

import { useGetAllDocumentTemplates } from 'docgen/hooks/useDocumentTemplates'
import { DocumentTemplateAggregate } from 'docgen/models/documenttemplate/DocumentTemplateAggregate'
import { DocumentTemplateTypeText } from '../document/DocumentTypeText'
import { ErrorContainer } from 'common/components'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllDocumentTemplatesGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  let toolbarOptions: any = ['Search']
  const allDocumentTemplatesData = useGetAllDocumentTemplates()

  let check: IFilter = {
    type: 'CheckBox',
  }

  function recordClicked(args: RecordClickEventArgs) {
    const clickedDocumentTemplateRecord = args.rowData as DocumentTemplateAggregate
    navigate('/docgen/documenttemplate/' + clickedDocumentTemplateRecord.id)
  }

  return (
    <>
      {allDocumentTemplatesData.isFetching && <Loading />}
      {allDocumentTemplatesData.error && <ErrorContainer error={allDocumentTemplatesData.error} />}
      {!allDocumentTemplatesData.isFetching && (
        <StyledGrid
          dataSource={allDocumentTemplatesData.data}
          allowPaging={true}
          allowSorting={true}
          toolbar={toolbarOptions}
          pageSettings={{ pageSize: 15, pageCount: 5 }}
          enableHover={true}
          enableHeaderFocus={true}
          locale={locale}
          allowSelection={true}
          recordClick={recordClicked.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective headerText="Name" field="name" width="250" textAlign="Left" />

            <ColumnDirective
              headerText="Document Type"
              field="documentType"
              width="150"
              textAlign="Left"
              filter={check}
              template={DocumentTemplateTypeText}
            />
            <ColumnDirective
              headerText="Language"
              field="language"
              width="100"
              textAlign="Left"
              filter={check}
            />
          </ColumnsDirective>
          <Inject services={[Sort, Page]} />
        </StyledGrid>
      )}
    </>
  )
}

export default AllDocumentTemplatesGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }

  cursor: pointer;
`
