import React, { memo, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  Sort,
  Toolbar,
  DetailRow,
  Selection,
  SelectionSettingsModel,
} from '@syncfusion/ej2-react-grids'

import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'
import * as helpers from 'common/helpers'
import {
  useDownloadTimeSheet,
  useSendTimeSheet,
  useGenerateTimeSheet,
  useGenerateMultiTimeSheet,
  useGetAllTimesheets,
  useSendMultiTimeSheet,
} from 'admindata/hooks/useTimeSheets'
import { TimeSheet, TimeSheetRecord } from 'admindata/models/timesheets/TimeSheet'
import {
  ButtonDefault,
  ButtonDownload,
  ButtonLoadingAction,
  ErrorContainer,
} from 'common/components'
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { ActionsBarContainer } from 'common/layout'
import { AlertDialogArgs, DialogUtility } from '@syncfusion/ej2-react-popups'
import { DownloadTimeSheetCmd } from 'admindata/contracts/timesheets/DownloadTimeSheetCmd'
import { GenerateTimeSheetCmd } from 'admindata/contracts/timesheets/GenerateTimeSheetCmd'
import { SendTimeSheetCmd } from 'admindata/contracts/timesheets/SendTimeSheetCmd'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllTimesheetsGrid = () => {
  const { locale } = useLocalize()
  let gridInstance = useRef<GridComponent>(null)
  const [dateFromValue, setdateFromValue] = useState(undefined)
  const [dateToValue, setdateToValue] = useState(undefined)

  const allTimesheetsData = useGetAllTimesheets(dateFromValue, dateToValue)

  const downloadTimeSheet = useDownloadTimeSheet()
  const generateTimeSheet = useGenerateTimeSheet()
  const generateMultiTimeSheet = useGenerateMultiTimeSheet()
  const sendTimeSheet = useSendTimeSheet()
  const sendMultiTimeSheet = useSendMultiTimeSheet()

  useEffect(() => {
    if (downloadTimeSheet.isSuccess && downloadTimeSheet.data) {
      var data = window.URL.createObjectURL(downloadTimeSheet.data)
      var link = document.createElement('a')
      document.body.appendChild(link)
      link.href = data
      link.target = '_blank'
      link.download =
        'Timesheet ' +
        downloadTimeSheet.variables?.consultantName +
        ' - ' +
        downloadTimeSheet.variables?.customerName +
        '.pdf'
      link.click()
      window.URL.revokeObjectURL(data)
      link.remove()
    }
  }, [
    downloadTimeSheet.data,
    downloadTimeSheet.isSuccess,
    downloadTimeSheet.variables?.consultantName,
    downloadTimeSheet.variables?.customerName,
  ])

  const handleChangeDateFrom = (args) => {
    setdateFromValue(args.value)
  }
  const handleChangeDateTo = (args) => {
    setdateToValue(args.value)
  }

  function loadClick() {
    allTimesheetsData.refetch()
  }

  const selectionSettings = {
    type: 'Multiple',
    checkboxOnly: true,
    persistSelection: true,
  } as SelectionSettingsModel
  let FilterType: any = {
    type: 'Menu',
  }
  const toolbarOptions: object[] = [{ text: 'Search', align: 'Right' }]

  const actionTemplate = (rowData) => {
    let error = false

    if (
      rowData.clientAggregate == null ||
      helpers.stringIsEmpty(rowData.customerVAT) ||
      helpers.stringIsEmpty(rowData.consultantEmail) ||
      helpers.stringIsEmpty(rowData.customerCustCode)
    )
      error = true

    return (
      <div>
        <ButtonLoadingAction
          bgColor={error ? stylesConfig.colorDanger : stylesConfig.color1}
          color="white"
          onClick={() => handleSingleGenerateClick(rowData)}
          loading={false}
          icon="bi bi-arrow-repeat"
        >
          Generate
        </ButtonLoadingAction>{' '}
        <ButtonDownload
          onClick={() => handleSingleDownloadClick(rowData)}
          bgColor={stylesConfig.colorWhite}
          disabled={!rowData.pdfGenerated}
        >
          PDF
        </ButtonDownload>{' '}
        <ButtonLoadingAction
          onClick={() => handleSingleSendClick(rowData)}
          bgColor={stylesConfig.colorWhite}
          loading={false}
          icon="bi bi-send"
          disabled={!rowData.pdfGenerated}
        ></ButtonLoadingAction>{' '}
      </div>
    )
  }
  const customerNameTemplate = (ts: TimeSheet) => {
    if (ts.clientAggregate == null) return ts.customerName

    return (
      <Link to={`/client/${ts.clientAggregate.id}`} target="_blank">
        {ts.customerName}
      </Link>
    )
  }
  const timeSheetRecordTemplate = (props: TimeSheet): any => {
    if (props === undefined) return

    let records = props.timeSheetRecords as TimeSheetRecord[]

    return (
      <div>
        <div>
          <b>Project Id: </b>
          {props.project_id}
          <br />
          <b>TimeSheet Id: </b>
          {props.timeSheetId}
          <br />
          <b>Client Address: </b>
          {props.clientAggregate?.address?.street} {props.clientAggregate?.address?.number}
          <br />
          {props.clientAggregate?.address?.zipCode} {props.clientAggregate?.address?.city}
          <br />
          <br />
        </div>
        <table width="100%">
          <thead>
            <tr>
              <th>Date Registration</th>
              <th>Prestatie</th>
              <th>Announced Hours</th>
              <th>Invoiced Hours</th>
              <th>Hours Prest.</th>
            </tr>
          </thead>
          <tbody>
            {records?.map((record) => (
              <tr key={record.timeSheetRecordId}>
                <td>{helpers.formatDateFromDate(record.date_registration)}</td>
                <td>{record.prestatie}</td>
                <td>{record.announced_hours}</td>
                <td>{record.invoiced_hours}</td>
                <td>{helpers.minutesToHoursString(record.announced_hours)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  const handleSingleGenerateClick = async (rowData: TimeSheet) => {
    if (rowData === undefined) {
      console.error('Single Generate - undefined')
      return
    }

    if (generateTimeSheet.isLoading) {
      DialogUtility.alert({
        content:
          'The document is already being created in the background. <br/> Please wait for the generation to complete.',
        title: 'Document generation is still in progress...',
      } as AlertDialogArgs)
      return
    }

    try {
      const ts = {
        timeSheetId: rowData.timeSheetId,
        project_id: rowData.project_id,
        customerName: rowData.customerName,
        customerCustCode: rowData.customerCustCode,
        customerAccCode: rowData.customerAccCode,
        customerVAT: rowData.customerVAT,
        clientAggregate: null,
        consultantName: rowData.consultantName,
        consultantFirstName: rowData.consultantFirstName,
        consultantLastName: rowData.consultantLastName,
        consultantEmail: rowData.consultantEmail,
        timeSheetRecords: rowData.timeSheetRecords,
        pdfGenerated: rowData.pdfGenerated,
        emailSent: rowData.emailSent,
      } as TimeSheet
      const generateTimeSheetCmd = {
        timeSheet: ts,
        clientName: rowData.customerName,
        clientVat: rowData.customerVAT,
        clientAddress: rowData.clientAggregate?.address,
      } as GenerateTimeSheetCmd

      generateTimeSheet.mutate(generateTimeSheetCmd)
    } catch (err) {
      console.error(err)
    }
  }
  const handleMultiGenerateClick = () => {
    const selectedRows = gridInstance.current?.getSelectedRecords() as TimeSheet[]
    if (selectedRows === undefined) {
      DialogUtility.alert({
        content: 'No data to generate. Please load data from AdminConsult first.',
        title: 'No data to generate',
      } as AlertDialogArgs)
      return
    }
    if (selectedRows.length < 0) {
      DialogUtility.alert({
        content: 'No rows selected. Please select the rows you want to Generate.',
        title: 'No rows selected',
      } as AlertDialogArgs)
      return
    }

    try {
      let multiGenerate = [] as GenerateTimeSheetCmd[]

      selectedRows.forEach((row) => {
        const ts = {
          timeSheetId: row.timeSheetId,
          project_id: row.project_id,
          customerName: row.customerName,
          customerCustCode: row.customerCustCode,
          customerAccCode: row.customerAccCode,
          customerVAT: row.customerVAT,
          clientAggregate: null,
          consultantName: row.consultantName,
          consultantFirstName: row.consultantFirstName,
          consultantLastName: row.consultantLastName,
          consultantEmail: row.consultantEmail,
          timeSheetRecords: row.timeSheetRecords,
          pdfGenerated: row.pdfGenerated,
          emailSent: row.emailSent,
        } as TimeSheet
        const generateTimeSheetCmd = {
          timeSheet: ts,
          clientName: row.customerName,
          clientVat: row.customerVAT,
          clientAddress: row.clientAggregate?.address,
        } as GenerateTimeSheetCmd

        multiGenerate.push(generateTimeSheetCmd)
      })

      generateMultiTimeSheet.mutate(multiGenerate)
    } catch (err) {
      console.error(err)
    }
  }
  const handleSingleDownloadClick = async (rowData: TimeSheet) => {
    if (rowData === undefined) {
      console.error('Single Download - undefined')
      return
    }
    if (!rowData.pdfGenerated) {
      DialogUtility.alert({
        content: 'The document is not yet ready. Please generate it first.',
        title: 'Unable to download document',
      } as AlertDialogArgs)
    } else {
      try {
        const cmd = {
          timeSheetId: rowData.timeSheetId,
          customerName: rowData.customerName,
          consultantName: rowData.consultantName,
        } as DownloadTimeSheetCmd
        downloadTimeSheet.mutate(cmd)
      } catch (err) {
        console.error(err)
      }
    }
  }
  const handleSingleSendClick = (rowData: TimeSheet) => {
    if (rowData === undefined) {
      console.error('Single Send - undefined')
      return
    }
    let dialog = DialogUtility.confirm({
      content:
        'Are you sure you want to send the timesheet email of ' +
        rowData.consultantName +
        ' to ' +
        rowData.consultantEmail +
        ' ?',
      title: 'Send email',
      okButton: { text: 'Send', click: okclick },
      cancelButton: { text: 'Cancel' },
    } as AlertDialogArgs)

    function okclick() {
      try {
        const cmd = {
          timeSheetId: rowData.timeSheetId,
          customerName: rowData.customerName,
          consultantName: rowData.consultantName,
          consultantFirstName: rowData.consultantFirstName,
          sendToEmail: rowData.consultantEmail,
        } as SendTimeSheetCmd
        sendTimeSheet.mutate(cmd)
        dialog.close()
      } catch (err) {
        console.error(err)
        dialog.close()
      }
    }
  }
  const handleMultiSendClick = () => {
    const selectedRows = gridInstance.current?.getSelectedRecords() as TimeSheet[]
    if (selectedRows === undefined) {
      DialogUtility.alert({
        content: 'No data to send. Please load data from AdminConsult first.',
        title: 'No data to send',
      } as AlertDialogArgs)
      return
    }
    if (selectedRows.length < 0) {
      DialogUtility.alert({
        content: 'No rows selected. Please select the rows you want to Send.',
        title: 'No rows selected',
      } as AlertDialogArgs)
      return
    }

    const filteredTimeSheets = selectedRows.filter((timeSheet) => !timeSheet.pdfGenerated)

    if (filteredTimeSheets.length > 0) {
      DialogUtility.alert({
        content:
          "Some selected timesheets have no PDF's. Please Generate them first. \r\n<br/> " +
          filteredTimeSheets.map((f) => {
            return '- ' + f.consultantName + '\r\n <br/>'
          }) +
          '',
        title: "Missing PDF's",
      } as AlertDialogArgs)
      return
    }

    let dialog = DialogUtility.confirm({
      content:
        'Are you sure you want to send the timesheet email to: \r\n<br/> ' +
        selectedRows.map((f) => {
          return '- ' + f.consultantName + '\r\n<br/>'
        }) +
        '',
      title: 'Sending multiple emails',
      okButton: { text: 'Send', click: okclick },
      cancelButton: { text: 'Cancel' },
    } as AlertDialogArgs)

    function okclick() {
      try {
        let multiSendCmd = [] as SendTimeSheetCmd[]
        selectedRows.forEach((rowData) => {
          const cmd = {
            timeSheetId: rowData.timeSheetId,
            customerName: rowData.customerName,
            consultantName: rowData.consultantName,
            consultantFirstName: rowData.consultantFirstName,
            sendToEmail: rowData.consultantEmail,
          } as SendTimeSheetCmd
          multiSendCmd.push(cmd)
        })

        sendMultiTimeSheet.mutate(multiSendCmd)
        dialog.close()
      } catch (err) {
        console.error(err)
        dialog.close()
      }
    }
  }
  return (
    <div>
      <div className="row">
        <div className="col-6">
          <TextBoxDiv>
            <MaskedTextBoxComponent
              mask="9999-99-99"
              value={dateFromValue}
              floatLabelType="Always"
              placeholder="Date From (format 2023-01-01)"
              change={handleChangeDateFrom}
              width="180px"
            />
          </TextBoxDiv>
          <TextBoxDiv>
            <MaskedTextBoxComponent
              mask="9999-99-99"
              value={dateToValue}
              floatLabelType="Always"
              placeholder="Date To (format 2023-01-31)"
              change={handleChangeDateTo}
              width="180px"
            />
          </TextBoxDiv>
        </div>
        <div className="col-6">
          <ActionsBarContainer width="100">
            <ButtonDefault float="left" onClick={loadClick} icon="bi-arrow-clockwise">
              Load from AdminC
            </ButtonDefault>{' '}
            <span></span>
            <ButtonDefault
              icon="bi-arrow-repeat"
              bgColor="#1d3557"
              color="white"
              onClick={handleMultiGenerateClick}
            >
              Multi Generate PDF's
            </ButtonDefault>{' '}
            <span></span>
            <ButtonDefault
              icon="bi bi-send"
              bgColor={stylesConfig.colorWhite}
              onClick={handleMultiSendClick}
            >
              Multi Send
            </ButtonDefault>
          </ActionsBarContainer>
        </div>
      </div>
      <br />
      {allTimesheetsData.isFetching && (
        <div>
          Loading Data... <Loading />
        </div>
      )}
      {generateTimeSheet.isLoading && (
        <div>
          Generating Pdf... <Loading />
        </div>
      )}
      {generateMultiTimeSheet.isLoading && (
        <div>
          Generating multiple Pdf's... <Loading />
        </div>
      )}
      {downloadTimeSheet.isLoading && (
        <div>
          Downloading Pdf... <Loading />
        </div>
      )}
      {sendTimeSheet.isLoading && (
        <div>
          Sending timesheet email... <Loading />
        </div>
      )}
      {sendMultiTimeSheet.isLoading && (
        <div>
          Sending multiple timesheet emails... <Loading />
        </div>
      )}
      {allTimesheetsData.error && <ErrorContainer error={allTimesheetsData.error} />}
      {generateTimeSheet.isError && <ErrorContainer error={generateTimeSheet.error} />}
      {generateMultiTimeSheet.isError && <ErrorContainer error={generateMultiTimeSheet.error} />}
      {downloadTimeSheet.isError && <ErrorContainer error={downloadTimeSheet.error} />}
      {sendTimeSheet.isError && <ErrorContainer error={sendTimeSheet.error} />}
      {sendMultiTimeSheet.isError && <ErrorContainer error={sendMultiTimeSheet.error} />}
      {!allTimesheetsData.isFetching &&
        !generateTimeSheet.isLoading &&
        !generateMultiTimeSheet.isLoading &&
        !downloadTimeSheet.isLoading &&
        !sendTimeSheet.isLoading &&
        !sendMultiTimeSheet.isLoading &&
        allTimesheetsData.data && (
          <GridContainer>
            <GridComponent
              ref={gridInstance}
              dataSource={allTimesheetsData.data}
              toolbar={toolbarOptions}
              filterSettings={FilterType}
              allowFiltering={true}
              allowSorting={true}
              locale={locale}
              allowSelection={true}
              selectionSettings={selectionSettings}
              detailTemplate={timeSheetRecordTemplate}
              enableImmutableMode={true}
            >
              <ColumnsDirective>
                <ColumnDirective type="checkbox" width="30" />
                <ColumnDirective
                  field="timeSheetId"
                  headerText="Id"
                  isPrimaryKey={true}
                  visible={false}
                />
                <ColumnDirective
                  field="customerName"
                  headerText="Customer Name"
                  width="120"
                  template={customerNameTemplate}
                />
                <ColumnDirective field="customerCustCode" headerText="Klnr" width="50" />
                <ColumnDirective field="customerVAT" headerText="VAT" width="100" />
                <ColumnDirective field="consultantName" headerText="Consultant" width="100" />
                <ColumnDirective
                  field="consultantEmail"
                  headerText="C. Email"
                  width="150"
                  allowEditing={true}
                />
                <ColumnDirective headerText="Actions" width="200" template={actionTemplate} />
              </ColumnsDirective>
              <Inject services={[Filter, Sort, Selection, Toolbar, DetailRow]} />
            </GridComponent>
          </GridContainer>
        )}
    </div>
  )
}

export default memo(AllTimesheetsGrid)

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }
  width: 100%;
  cursor: pointer;
`
const GridContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`

const TextBoxDiv = styled.div`
  width: 180px;
  float: left;
  margin-right: 15px;
`
