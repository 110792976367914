import React from 'react'
import { createRoot } from 'react-dom/client'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from 'services/auth/authConfig'

import App from './App'
import * as serviceWorker from './serviceWorker'

const msalInstance = await PublicClientApplication.createPublicClientApplication(msalConfig)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
)

serviceWorker.unregister()
