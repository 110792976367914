import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as stylesConfig from 'common/styles/stylesConfig'
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  GridComponent,
  IEditCell,
  Inject,
  Page,
  Toolbar,
  RowDD,
  Selection,
} from '@syncfusion/ej2-react-grids'
import { DataManager } from '@syncfusion/ej2-data'
import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import {
  Loading,
  ErrorContainer,
  BoxContainer,
  ButtonDelete,
  ButtonEdit,
  ButtonDefault,
} from 'common/components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import styled from 'styled-components'
import {
  useGetDocumentTemplateById,
  useUpdateDocumentTemplatePlaceholders,
  useUpdateDocumentTemplateDetails,
} from 'docgen/hooks/useDocumentTemplates'
import * as helpers from 'common/helpers'
import { FieldHolder } from 'docgen/models/documenttemplate/FieldHolder'
import { UpdateDocumentTemplatePlaceholdersCmd } from 'docgen/contracts/documenttemplate/UpdateDocumentTemplatePlaceholdersCmd'
import { Placeholders } from 'docgen/models/documenttemplate/Placeholders'
import { FieldTypes } from 'docgen/models/documenttemplate/FieldTypes'
import { DropDownList } from '@syncfusion/ej2-react-dropdowns'
import { builtInFieldsArray } from 'docgen/models/documenttemplate/BuiltInFields'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import {
  AnimationSettingsModel,
  ButtonPropsModel,
  DialogComponent,
} from '@syncfusion/ej2-react-popups'
import { UpdateDocumentTemplateDetailsCmd } from 'docgen/contracts/documenttemplate/UpdateDocumentTemplateDetailsCmd'

L10n.load(SYNCFUSIONTRANSLATIONS)

const DocumentTemplateView = () => {
  const { locale } = useLocalize()
  let { id } = useParams()

  //HOOKS
  const documentTemplateData = useGetDocumentTemplateById(id || '')
  const updateDocumentTemplatePlaceholders = useUpdateDocumentTemplatePlaceholders()
  const updateDocumentTemplateDetails = useUpdateDocumentTemplateDetails()

  // VALIDATIONS
  const validRequired = { required: true }
  const validRequiredUnique = {
    required: true,
    minLength: [
      (e) => {
        let fields = documentTemplateData.data?.placeHolders?.fields
        let newValue = e['value']
        let oldValue = e.element.defaultValue

        if (newValue === oldValue) {
          return true
        }
        if (fields?.find((f) => f.name === newValue)) {
          return false
        }

        return true
      },
      'This field name is already in use',
    ],
  }

  //VARS
  const [lastFieldId, setLastFieldId] = useState(1)
  let gridFields = useRef<GridComponent>(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  let nameInput: TextBoxComponent | null
  let documentUrlInput: TextBoxComponent | null
  let promptDialogInstance = useRef<DialogComponent>(null)
  let editDetailsButtons = [
    {
      click: () => {
        const changedDetails = {
          id: id,
          name: nameInput?.value,
          documentUrl: documentUrlInput?.value,
        } as UpdateDocumentTemplateDetailsCmd

        updateDocumentTemplateDetails.mutate(changedDetails)
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Save', isPrimary: true },
    },
    {
      click: () => {
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Cancel' },
    },
  ] as ButtonPropsModel[]
  let animationSettings = { effect: 'None' } as AnimationSettingsModel
  const toolbarOptions: any = ['Add', 'Delete', 'Update', 'Cancel']
  const editSettings: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    mode: 'Batch',
  }
  let editTypes: FieldTypes[] = [
    { name: 'Text', value: 'string' },
    { name: 'Number', value: 'number' },
  ]
  let builtInFields = builtInFieldsArray()
  let elem: any
  let dropDownListObj: any
  let editCell: IEditCell = {
    create: () => {
      elem = document.createElement('input')
      return elem
    },
    read: () => {
      return dropDownListObj.value
    },
    destroy: () => {
      dropDownListObj.destroy()
    },
    write: (args) => {
      dropDownListObj = new DropDownList({
        dataSource: new DataManager(editTypes),
        fields: { text: 'name', value: 'value' },
        value: args.rowData[args.column.field],
      })
      dropDownListObj.appendTo(elem)
    },
  }

  const TypeTemplate = (data: FieldHolder) => {
    if (editTypes && data) {
      const typeFound = editTypes.find((element) => {
        return element.value === data.type
      })

      if (typeFound) return typeFound.name
      else return 'Not Found'
    } else return '-'
  }

  //FUNCTIONS
  function openTemplateFileClick() {
    if (documentTemplateData.data?.documentUrl !== undefined)
      window.open(documentTemplateData.data?.documentUrl)
  }

  function updatePlaceholders(newPlaceholders: FieldHolder[]) {
    if (newPlaceholders.length > 0) {
      const updateCmd = {
        id: documentTemplateData.data?.id,
        placeHolders: {
          fields: newPlaceholders,
          tables: null,
        } as Placeholders,
      } as UpdateDocumentTemplatePlaceholdersCmd
      updateDocumentTemplatePlaceholders.mutate(updateCmd)
    } else {
      console.error('Placeholderfields is empty')
    }
  }

  function dataBound() {
    const src = gridFields.current?.dataSource
    const json = JSON.stringify(src)
    const updatedFields = JSON.parse(json) as FieldHolder[]
    if (updatedFields.length > 0) {
      const sorted = updatedFields.sort((a, b) => (a.id > b.id ? 1 : -1))
      setLastFieldId(sorted[sorted.length - 1].id + 1)
    }
  }

  function beforeBatchAdd(args: any) {
    setLastFieldId(lastFieldId + 1)
    args.defaultData.id = lastFieldId
    args.defaultData.type = 'string'
    args.defaultData.name = ''
    args.defaultData.description = ''
    args.defaultData.defaultValue = ''
  }

  function actionCompletedFields(args: any) {
    const src = gridFields.current?.dataSource
    const json = JSON.stringify(src)
    const updatedFields = JSON.parse(json) as FieldHolder[]

    if (
      args.requestType === 'batchsave' &&
      args.name === 'actionComplete' &&
      args.cancel === false
    ) {
      try {
        updatePlaceholders(updatedFields)
      } catch (err) {
        console.error(err)
      }
    }
  }

  function btnEditClick() {
    setEditDialogOpen(true)
  }
  function editDialogClose() {
    setEditDialogOpen(false)
  }

  return (
    <>
      {documentTemplateData.isFetching && <Loading />}
      {documentTemplateData.error && <ErrorContainer error={documentTemplateData.error} />}
      {!documentTemplateData.isFetching && !documentTemplateData.error && (
        <>
          <div className="container-fluid">
            <TitleContainer
              title="Document Template"
              subtitle={documentTemplateData.data?.name || 'DocumentTemplate not found'}
            />
            <ActionsBarContainer>
              <ButtonDefault
                bgColor={stylesConfig.colorWhite}
                onClick={openTemplateFileClick}
                icon="bi-file-earmark-word"
              >
                Open Template File
              </ButtonDefault>{' '}
              <ButtonEdit onClick={btnEditClick}></ButtonEdit>{' '}
              <ButtonDelete
                onClick={() => alert('Are you sure you want to delete this Template?')}
                disabled
              ></ButtonDelete>{' '}
            </ActionsBarContainer>
          </div>
          <ContentContainer>
            <div className="row" id="dialogTarget">
              <div className="col-7">
                <BoxContainer title="Document information">
                  Name: <b>{documentTemplateData.data?.name}</b>
                  <br />
                  Type: <b>{documentTemplateData.data?.documentType}</b>
                  <br />
                  Language: <b>{documentTemplateData.data?.language}</b>
                  <br />
                  Template File:{' '}
                  <b>...{helpers.toLastPartOfUrl(documentTemplateData.data?.documentUrl)}</b>
                  <br />
                </BoxContainer>
              </div>
              <div className="col-5">
                <BoxContainer title="History">
                  Created By: <b>{documentTemplateData.data?.auditData?.createdBy}</b>
                  <br />
                  Created On:{' '}
                  <b>{helpers.formatDate(documentTemplateData.data?.auditData?.createdOn)}</b>
                  <br />
                  Last Updated By:{' '}
                  <b>
                    {documentTemplateData.data?.auditData?.updateInfo &&
                    documentTemplateData.data?.auditData?.updateInfo.length > 0
                      ? documentTemplateData.data?.auditData?.updateInfo.slice(-1).pop()?.updatedBy
                      : ''}
                  </b>
                  <br />
                  Last Updated On:{' '}
                  <b>
                    {helpers.formatDate(
                      documentTemplateData.data?.auditData?.updateInfo.slice(-1).pop()?.updatedOn
                    )}
                  </b>
                  <br />
                </BoxContainer>
              </div>
              <div className="col-12">
                <br />
                <StyledTabContent>
                  <BoxContainer title="Template Fields">
                    <StyledGrid
                      ref={gridFields}
                      dataSource={
                        documentTemplateData.data?.placeHolders?.fields || ([] as FieldHolder[])
                      }
                      allowPaging={false}
                      allowSorting={true}
                      allowFiltering={false}
                      editSettings={editSettings}
                      toolbar={toolbarOptions}
                      beforeBatchAdd={beforeBatchAdd}
                      actionComplete={actionCompletedFields}
                      dataBound={dataBound}
                      allowRowDragAndDrop={true}
                      selectionSettings={{ type: 'Single', mode: 'Row' }}
                    >
                      <ColumnsDirective>
                        <ColumnDirective
                          allowEditing={true}
                          headerText="Field Nr"
                          field="id"
                          width="50"
                          textAlign="Left"
                          uid="id"
                          isPrimaryKey={true}
                          type="number"
                          visible={false}
                        />
                        <ColumnDirective
                          allowEditing={true}
                          headerText="Field Name"
                          field="name"
                          width="auto"
                          textAlign="Left"
                          validationRules={validRequiredUnique}
                          uid="name"
                        />
                        <ColumnDirective
                          allowEditing={true}
                          headerText="Field Description"
                          field="description"
                          width="auto"
                          textAlign="Left"
                          uid="description"
                        />
                        <ColumnDirective
                          allowEditing={true}
                          headerText="Default Value"
                          field="defaultValue"
                          width="auto"
                          textAlign="Left"
                          uid="defaultValue"
                        />
                        <ColumnDirective
                          headerText="Type"
                          field="type"
                          uid="type"
                          width="200"
                          textAlign="Left"
                          validationRules={validRequired}
                          editType="dropdownedit"
                          edit={editCell}
                          template={TypeTemplate}
                        ></ColumnDirective>
                      </ColumnsDirective>
                      <Inject services={[RowDD, Page, Toolbar, Edit, Selection]} />
                    </StyledGrid>
                  </BoxContainer>
                </StyledTabContent>
              </div>
              <div className="col-12">
                <br />
                <StyledTabContent>
                  <BoxContainer title="Built-in Fields">
                    <StyledGrid dataSource={builtInFields}>
                      <ColumnsDirective>
                        <ColumnDirective
                          headerText="Field Name"
                          field="name"
                          width="500"
                          textAlign="Left"
                          uid="name"
                        />
                      </ColumnsDirective>
                    </StyledGrid>
                  </BoxContainer>
                </StyledTabContent>
              </div>
            </div>
          </ContentContainer>
          <StyledDialogComponent
            id="promptDialog"
            header={'Edit details of ' + documentTemplateData?.data?.name}
            visible={editDialogOpen}
            showCloseIcon={true}
            animationSettings={animationSettings}
            width="600px"
            height="350px"
            ref={promptDialogInstance}
            target="#dialogTarget"
            buttons={editDetailsButtons}
            close={editDialogClose.bind(this)}
            allowDragging={true}
          >
            {/* Prompt Dialog content  */}
            <table className="e-table" cellSpacing="6px">
              <tbody>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" colSpan={2}>
                    <TextBoxComponent
                      type="text"
                      placeholder="Name"
                      value={documentTemplateData.data?.name}
                      floatLabelType="Always"
                      ref={(n) => (nameInput = n)}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell">
                    <TextBoxComponent
                      type="text"
                      placeholder="Document Template Url"
                      value={documentTemplateData.data?.documentUrl}
                      floatLabelType="Always"
                      ref={(n) => (documentUrlInput = n)}
                      width={'550px'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
              </tbody>
            </table>
          </StyledDialogComponent>
        </>
      )}
    </>
  )
}

export default DocumentTemplateView

const StyledGrid = styled(GridComponent)`
  cursor: pointer;
`

const StyledTabContent = styled.div`
  margin: 15px 0;
`

const StyledDialogComponent = styled(DialogComponent)`
  max-height: unset !important;
  top: 20px !important;
`
const StyledDialogTr = styled.tr``
const StyledDialogTd = styled.td`
  padding: 5px 50px 20px 0;
`
