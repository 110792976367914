import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { Loading, BoxContainer, ErrorContainer } from 'common/components'

import * as stylesConfig from 'common/styles/stylesConfig'

import { Address } from 'coredata/models/BaseTypes'
import { RegisterNewOffice } from 'coredata/contracts/office/RegisterNewOffice'
import { useRegisterNewOffice } from 'coredata/hooks/useOffices'
import { GetLoggedInUser } from 'services/auth/authService'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AddOfficeForm = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  const registerNewOffice = useRegisterNewOffice()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [externalId, setExternalId] = useState('')

  const [boxtitle] = useState('Add new office')

  const account = GetLoggedInUser()

  function cancel() {
    navigate('/offices')
  }
  function save() {
    try {
      const office = {
        externalId: externalId,
        name: name,
        email: email,
        address: {
          countryIso2: 'BE',
          zipCode: '',
          street: '',
          number: '',
        } as Address,
      } as RegisterNewOffice

      registerNewOffice.mutate(office)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {registerNewOffice.error && <ErrorContainer error={registerNewOffice.error} />}

      <BoxContainer title={boxtitle}>
        <div>
          <TextBoxComponent
            key="name"
            change={(event) => setName(event.value as string)}
            placeholder="Office Name *"
            floatLabelType="Auto"
            disabled={registerNewOffice.isLoading}
          />
          <TextBoxComponent
            key="externalId"
            change={(event) => setExternalId(event.value as string)}
            placeholder="Office number *"
            floatLabelType="Auto"
            disabled={registerNewOffice.isLoading}
          />
          <TextBoxComponent
            key="email"
            change={(event) => setEmail(event.value as string)}
            placeholder="Email address *"
            floatLabelType="Auto"
            disabled={registerNewOffice.isLoading}
          />
        </div>
        {registerNewOffice.isLoading && <Loading />}

        {!registerNewOffice.isLoading && (
          <StyledValidateButtonsdiv>
            <StyledActionButton onClick={cancel}>Cancel</StyledActionButton>
            <StyledActionButton onClick={save}>Save</StyledActionButton>
          </StyledValidateButtonsdiv>
        )}
      </BoxContainer>
    </>
  )
}

export default AddOfficeForm

const StyledValidateButtonsdiv = styled.div`
  margin: 35px 0;
  text-align: right;
`

const StyledActionButton = styled(ButtonComponent)`
  margin-left: 35px;
  width: 100px;
`
