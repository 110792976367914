import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false, // 2 (default)
      //staleTime: 1000 * 60 * 2, // 2 minutes
    },
  },
})
