import { IDTokenClaims } from './authModels'
import { GetLoggedInUser } from './authService'

export function VerifyPermissions(role: string): boolean {
  const user = GetLoggedInUser()
  let claims: IDTokenClaims

  if (user.idTokenClaims) {
    claims = user.idTokenClaims as IDTokenClaims
    if (claims.roles?.includes(role)) return true
  }
  return false
}
