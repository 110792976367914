import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MenuComponent, MenuItemModel, SidebarComponent } from '@syncfusion/ej2-react-navigations'
import { useLocalize } from 'localize-react'
import styled from 'styled-components'
import { GetWebAppVersion, GetWebAppCustomNodeEnv } from 'services/appsettings'
import { UserRoles } from 'services/auth/userRoles'
import { VerifyPermissions } from 'services/auth/authorizationService'

const SideBar = () => {
  const { translate } = useLocalize()
  let mediaQuery: string = '(min-width: 600px)'
  let enableDock: boolean = true
  let dockSize: string = '52px'
  let width: string = '220px'
  let target: string = '.main-content'
  const [dockState, setDockState] = useState(true)

  let menuItems: MenuItemModel[] = [
    {
      text: translate('global.sidebarMenu.dashboard'),
      iconCss: 'bi bi-house-door-fill',
    },
    {
      text: translate('global.sidebarMenu.clients'),
      iconCss: 'bi bi-building',
      url: '/clients',
    },
    {
      text: translate('global.sidebarMenu.docgen'),
      iconCss: 'bi bi-file-earmark-text',
      url: '/docgen/documents',
    },
  ]
  let menuItemsAdmin: MenuItemModel[] = [
    {
      text: 'Clients Sync',
      iconCss: 'bi bi-people-fill',
      url: '/admindata/adminconsult/customers',
    },
    {
      text: 'PJS Timesheets',
      iconCss: 'bi bi-calendar-check-fill',
      url: '/admindata/adminconsult/timesheets',
    },
  ]
  let menuItemsConfiguration: MenuItemModel[] = [
    {
      text: translate('global.sidebarMenu.offices'),
      iconCss: 'bi bi-building',
      url: '/offices',
    },
    {
      text: 'Document Templates',
      iconCss: 'bi bi-file-earmark-text',
      url: '/docgen/documenttemplates',
    },
  ]
  const open = () => {
    setDockState(true)
  }
  const close = () => {
    setDockState(false)
  }
  const sidebarMenuTemplate = (data: any) => {
    let links = data.properties
    return (
      <StyledLink to={links.url} className="e-menu-text e-menu-url">
        <div className="e-anchor-wrap">
          <StyledIcon className={'e-menu-icon ' + links.iconCss}></StyledIcon>
          <StyledText>{links.text}</StyledText>
        </div>
      </StyledLink>
    )
  }

  return (
    <SidebarComponent
      id="sidebar-menu"
      enableDock={enableDock}
      mediaQuery={mediaQuery}
      dockSize={dockSize}
      width={width}
      target={target}
      close={close}
      open={open}
    >
      <StyledMenuGroup className="main-menu">
        <StyledMenuTitle className="main-menu-header">Main</StyledMenuTitle>
        <StyledMenuComponent
          items={menuItems}
          orientation="Vertical"
          cssClass="dock-menu"
          template={sidebarMenuTemplate}
        ></StyledMenuComponent>
      </StyledMenuGroup>
      {VerifyPermissions(UserRoles.portalAdmin) && (
        <StyledMenuGroup className="main-menu">
          <StyledMenuTitle className="main-menu-header">Admin</StyledMenuTitle>
          <MenuComponent
            items={menuItemsAdmin}
            orientation="Vertical"
            cssClass="dock-menu"
            template={sidebarMenuTemplate}
          ></MenuComponent>
        </StyledMenuGroup>
      )}
      {VerifyPermissions(UserRoles.portalAdmin) && (
        <StyledMenuGroup className="main-menu">
          <StyledMenuTitle className="main-menu-header">Configuration</StyledMenuTitle>
          <MenuComponent
            items={menuItemsConfiguration}
            orientation="Vertical"
            cssClass="dock-menu"
            template={sidebarMenuTemplate}
          ></MenuComponent>
        </StyledMenuGroup>
      )}

      <AppInfoMenu state={dockState}>
        env: {GetWebAppCustomNodeEnv()} <br />
        version: {GetWebAppVersion()}
      </AppInfoMenu>
    </SidebarComponent>
  )
}

export default SideBar

interface DockstateProps {
  state: boolean
}

const StyledLink = styled(Link)`
  color: #fff !important;
  text-decoration: none !important;
`

const StyledIcon = styled.i`
  margin-top: -6px;
  ::before {
    color: #fff !important;
  }
`

const StyledText = styled.span`
  padding-left: 15px;
`

const StyledMenuGroup = styled.div`
  .dock-menu {
    background-color: red !important;
  }
`

const StyledMenuTitle = styled.p`
  margin-bottom: 0 !important;
  padding-bottom: 5px !important;
  background-color: #1d3557;
`
const StyledMenuComponent = styled(MenuComponent)``
const AppInfoMenu = styled.div<DockstateProps>`
  display: ${(props) => (props.state ? 'block' : 'none')};
  height: 40px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 5px 10px;
  text-align: left;
`
