import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'

import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { Loading, BoxContainer, ErrorContainer, CustomErrorContainer } from 'common/components'

import * as stylesConfig from 'common/styles/stylesConfig'

import { ClientContactInformation } from 'coredata/models/client/ClientAggregate'
import { DocumentType, DocumentTypeArray } from 'docgen/models/DocumentType'
import { Recipient } from 'docgen/models/document/Recipient'

import { AddDocumentCmd } from 'docgen/contracts/document/AddDocumentCmd'
import { useAddDocument } from 'docgen/hooks/useDocuments'
import { useGetAllDocumentTemplates } from 'docgen/hooks/useDocumentTemplates'
import { useGetAllClients } from 'coredata/hooks/useClients'
import { ActorType } from 'docgen/models/document/ActorType'
import { GetLoggedInUser } from 'services/auth/authService'
import { DocumentTemplateAggregate } from 'docgen/models/documenttemplate/DocumentTemplateAggregate'
import { toCamelCase } from 'common/helpers'
import { useGetAllOffices } from 'coredata/hooks/useOffices'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AddDocumentForm = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()

  const addDocument = useAddDocument()
  const allDocumentTemplates = useGetAllDocumentTemplates()
  const allClients = useGetAllClients()
  const allOffices = useGetAllOffices()

  const [clientId, setClientId] = useState('')
  const [recipient, setRecipient] = useState({} as Recipient)
  const types = DocumentTypeArray()
  const [type, setType] = useState({} as DocumentType)
  const [language, setLanguage] = useState('')
  const [officeId, setOfficeId] = useState('')
  const [documentTemplateId, setDocumentTemplateId] = useState('')
  const [validationErrors, setValidationErrors] = useState(false)
  const [validationErrorMessage, setValidationErrorMessage] = useState([] as string[])
  const [allClientContacts, setAllClientContacts] = useState({} as Array<ClientContactInformation>)
  const [filteredDocumentTemplates, setFilteredDocumentTemplates] = useState(
    {} as Array<DocumentTemplateAggregate>
  )

  const [boxtitle] = useState('Add new document')

  const loggedInUser = GetLoggedInUser()

  function cancel() {
    navigate('/docgen/documents')
  }
  function save() {
    let errors = false
    let errorMessages: string[] = []
    if (clientId === '') {
      errorMessages.push('Please select a Client')
      errors = true
    }
    if (Object.keys(recipient).length === 0) {
      errorMessages.push('Please select a Contact')
      errors = true
    }
    if (officeId === '') {
      errorMessages.push('Please select an Office')
      errors = true
    }
    if (Object.keys(type).length === 0) {
      errorMessages.push('Please select a Type')
      errors = true
    }
    if (language === '') {
      errorMessages.push('Please select a Language')
      errors = true
    }
    if (documentTemplateId === '') {
      errorMessages.push('Please select a Document Template')
      errors = true
    }

    setValidationErrorMessage(errorMessages)
    setValidationErrors(errors)
    try {
      if (errors === false) {
        const addDocumentCmd = {
          type: type,
          clientId: clientId,
          documentTemplateId: documentTemplateId,
          officeId: officeId,
          language: language,
          recipient: recipient,
          createdBy: loggedInUser.username,
        } as AddDocumentCmd

        addDocument.mutate(addDocumentCmd)
      }
    } catch (err) {
      console.log(err)
    }
  }

  function ddlClientSelected(event: any) {
    setClientId(event.itemData.value.id)
    setAllClientContacts(event.itemData.value.contacts as Array<ClientContactInformation>)
  }
  function ddlTypeSelected(event: any) {
    setType(event.value as DocumentType)
    if (language !== '') {
      let filtered = allDocumentTemplates.data?.filter(
        (a) =>
          a.language === language &&
          toCamelCase(a.documentType.toString()) === toCamelCase(event.value.toString())
      )
      if (filtered) setFilteredDocumentTemplates(filtered)
    }
  }
  function ddlLanguageSelected(event: any) {
    setLanguage(event.value as string)
    if (type !== undefined) {
      let filtered = allDocumentTemplates.data?.filter(
        (a) =>
          a.language === event.value &&
          toCamelCase(a.documentType.toString()) === toCamelCase(type.toString())
      )
      if (filtered)
        setFilteredDocumentTemplates(
          filtered.sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
        )
    }
  }
  return (
    <StyledAddForm>
      {validationErrors && (
        <CustomErrorContainer
          errorTitle={'Unable to add new document'}
          errorMessages={validationErrorMessage}
        />
      )}
      <BoxContainer title={boxtitle}>
        {addDocument.isLoading && <Loading />}
        {addDocument.error && <ErrorContainer error={addDocument.error} />}

        {!addDocument.isLoading && allClients.data && allDocumentTemplates.data && (
          <div>
            <b>Document Info</b>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlClients"
                key="client"
                dataSource={allClients.data
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((client) => {
                    return { text: client.name, value: client }
                  })}
                placeholder="Select a Client"
                disabled={addDocument.isLoading}
                floatLabelType="Auto"
                change={(event) => {
                  ddlClientSelected(event)
                }}
              />
            </StyledDdlContainer>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlClientContacts"
                key="recipient"
                dataSource={
                  allClientContacts.length > 0
                    ? allClientContacts.map((clientcontact) => {
                        return {
                          value: clientcontact,
                          text: clientcontact.firstName + ' ' + clientcontact.lastName,
                        }
                      })
                    : ['No contacts available for this client']
                }
                floatLabelType="Auto"
                placeholder="Select a Contact"
                disabled={addDocument.isLoading}
                change={(event) => {
                  if (event.itemData) {
                    setRecipient({
                      name: event.value,
                      email: event.itemData.value.email.value,
                    } as Recipient)
                  } else {
                    setRecipient({} as Recipient)
                  }
                }}
              />
            </StyledDdlContainer>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlOffice"
                key="office"
                dataSource={allOffices.data?.map((office) => {
                  return {
                    value: office.id,
                    text: office.name,
                  }
                })}
                placeholder="Select an Office"
                floatLabelType="Auto"
                disabled={addDocument.isLoading}
                change={(event) => setOfficeId(event.itemData.value as string)}
              />
            </StyledDdlContainer>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlType"
                key="type"
                dataSource={types}
                placeholder="Select a Type"
                floatLabelType="Auto"
                disabled={addDocument.isLoading}
                change={(event) => {
                  ddlTypeSelected(event)
                }}
              />
            </StyledDdlContainer>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlLanguage"
                key="language"
                dataSource={['NL', 'FR', 'EN']}
                placeholder="Select a Language"
                floatLabelType="Auto"
                disabled={addDocument.isLoading}
                change={(event) => {
                  ddlLanguageSelected(event)
                }}
              />
            </StyledDdlContainer>
            <StyledDdlContainer>
              <DropDownListComponent
                id="ddlDocumentTemplate"
                key="documenttemplate"
                dataSource={
                  filteredDocumentTemplates.length > 0
                    ? filteredDocumentTemplates.map((documentTemplate) => {
                        return {
                          value: documentTemplate.id,
                          text: documentTemplate.name,
                        }
                      })
                    : ['No templates available for selected Type & Language.']
                }
                floatLabelType="Auto"
                placeholder="Select a Document Template"
                disabled={addDocument.isLoading}
                change={(event) => setDocumentTemplateId(event.itemData.value as string)}
              />
            </StyledDdlContainer>
            <StyledValidateButtonsdiv>
              <StyledActionButton onClick={cancel}>Cancel</StyledActionButton>
              <StyledActionButton onClick={save}>Save</StyledActionButton>
            </StyledValidateButtonsdiv>
          </div>
        )}
      </BoxContainer>
    </StyledAddForm>
  )
}

export default AddDocumentForm

const StyledDdlContainer = styled.div``

const StyledValidateButtonsdiv = styled.div`
  margin: 35px 0;
  text-align: right;
`

const StyledActionButton = styled(ButtonComponent)`
  margin-left: 35px;
  width: 100px;
`
const StyledAddForm = styled.div`
  margin-top: -45px;
`
