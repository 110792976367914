import styled from 'styled-components'

export interface QuickLinkData {
  logoUrl: string
  title: string
  link: string
}

interface Props {
  children?: React.ReactNode
  link: string
  logo: string
}

export const QuickLink = (props: Props) => {
  return (
    <div className="col-2">
      <a href={props.link} target="_blank" rel="noreferrer">
        <QuickLinkBlock className="avatar-block">
          <QuickLinkContent className="e-card e-avatar-showcase">
            <QuickLinkCardContent className="e-card-content">
              <QuickLinkAvatar className="e-avatar e-avatar-xlarge e-avatar-square">
                <img className="image" src={props.logo} alt="avatar" />
              </QuickLinkAvatar>
            </QuickLinkCardContent>
            <QuickLinkCardContent className="e-card-content">
              <div>{props.children}</div>
            </QuickLinkCardContent>
          </QuickLinkContent>
        </QuickLinkBlock>
      </a>
    </div>
  )
}

const QuickLinkBlock = styled.div`
  width: 150px;
  height: 85px;
  float: left;
  cursor: pointer;
`

const QuickLinkContent = styled.div`
  text-align: center;
`

const QuickLinkCardContent = styled.div`
  line-height: 1 !important;
`

const QuickLinkAvatar = styled.div`
  width: 100px !important;
  background-color: white;
  height: 38px !important;
`
