import React from 'react'
import { Link } from 'react-router-dom'
import { MenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations'
import { useLocalize } from 'localize-react'
import styled from 'styled-components'
import { AccountInfo } from '@azure/msal-browser'
import { toInitials } from 'common/helpers'
import { GetMyProfileUri } from 'services/appsettings'

interface TopBarMenuProps {
  loggedInAccountInfo: AccountInfo
}

const TopBarMenu = (props: TopBarMenuProps) => {
  const { translate } = useLocalize()

  let AccountMenuItem: MenuItemModel[] = [
    {
      text: props.loggedInAccountInfo.name,
      items: [
        { text: translate('global.accountMenu.settings'), url: '/settings' },
        { text: translate('global.accountMenu.profile'), url: '/profile' },
        { text: translate('global.accountMenu.signout'), url: '/signout' },
      ],
    },
  ]

  const menuTemplate = (data: any) => {
    let links = data.properties
    if (links.url === '/profile') {
      return (
        <StyledExternalLink href={GetMyProfileUri()} target="_blank" rel="noopener noreferrer">
          {links.text}
        </StyledExternalLink>
      )
    } else {
      return <StyledLink to={links.url}>{links.text}</StyledLink>
    }
  }

  return (
    <>
      <li className="right-header list">
        <StyledAvatar className="e-avatar e-avatar-circle">
          {toInitials(props.loggedInAccountInfo.name)}
        </StyledAvatar>
      </li>
      <li className="right-header list">
        <StyledHorizontalMenu className="horizontal-menu">
          <StyledMenuComponent
            items={AccountMenuItem}
            cssClass="dock-menu"
            template={menuTemplate}
          ></StyledMenuComponent>
        </StyledHorizontalMenu>
      </li>

      <li className="right-header list">
        <i className="bi bi-bell" />
      </li>
    </>
  )
}

export default TopBarMenu

const StyledAvatar = styled.span`
  background-color: #fff;
  color: #33383e !important;
`

const StyledLink = styled(Link)`
  color: #fff !important;
  text-decoration: none !important;
`
const StyledExternalLink = styled.a`
  color: #fff !important;
  text-decoration: none !important;
`

const StyledMenuComponent = styled(MenuComponent)`
  background-color: #33383e !important;
  color: #fff !important;
  overflow: hidden;
`

const StyledHorizontalMenu = styled.div`
  li {
    line-height: 35px !important;
  }
  .e-menu-item {
    height: 35px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 35px;
  }
  .e-caret {
    line-height: 35px !important;
  }
`
