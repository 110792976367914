import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  IFilter,
  Inject,
  Sort,
  Toolbar,
  Page,
  RecordClickEventArgs,
} from '@syncfusion/ej2-react-grids'

import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'
import * as helpers from 'common/helpers'
import { useGetAllCustomers } from 'admindata/hooks/useTimeSheets'
import { AdminConsultCustomer } from 'admindata/models/clientsync/Customer'
import { ErrorContainer } from 'common/components'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllCustomersGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()

  const allCustomersData = useGetAllCustomers()

  let toolbarOptions: any = ['Search']

  let check: IFilter = {
    type: 'CheckBox',
  }
  let FilterType: any = {
    type: 'Menu',
  }

  return (
    <>
      {allCustomersData.isFetching && <Loading />}
      {allCustomersData.error && <ErrorContainer error={allCustomersData.error} />}
      {!allCustomersData.isFetching && (
        <GridComponent
          dataSource={allCustomersData.data}
          toolbar={toolbarOptions}
          allowPaging={true}
          filterSettings={FilterType}
          allowFiltering={true}
          allowSorting={true}
          pageSettings={{ pageSize: 50, pageCount: 5 }}
          enableHover={true}
          enableHeaderFocus={true}
          locale={locale}
          allowSelection={true}
          sortSettings={{ columns: [{ field: 'creationDate', direction: 'Descending' }] }}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="IsActive"
              field="isActive"
              width="75"
              textAlign="Left"
              allowFiltering={true}
            />
            <ColumnDirective
              headerText="CustomerId"
              field="customerId"
              width="75"
              textAlign="Left"
              allowFiltering={true}
            />
            <ColumnDirective
              headerText="CustomerCrmType"
              field="customerCrmType"
              width="75"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="CustCode"
              field="custCode"
              width="75"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="AccCode"
              field="accCode"
              width="75"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Title"
              field="title"
              width="75"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Name"
              field="name"
              width="150"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Email"
              field="email"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="VATNr"
              field="vATNr"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="CustomerGroupLabel"
              field="customerGroupLabel"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="CreationDate"
              field="creationDate"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
          </ColumnsDirective>
          <Inject services={[Filter, Sort, Toolbar, Page]} />
        </GridComponent>
      )}
    </>
  )
}

export default AllCustomersGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }

  cursor: pointer;
`
