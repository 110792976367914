import React from 'react'
import { Link } from 'react-router-dom'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import AllDocumentTemplatesGrid from 'docgen/components/documenttemplate/AllDocumentTemplatesGrid'
import { ButtonAdd } from 'common/components/Buttons'

const AllDocumentTemplatesView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="Document Templates" />
        <ActionsBarContainer>
          <Link to={'/docgen/documenttemplate/add'}>
            <ButtonAdd>New template</ButtonAdd>{' '}
          </Link>
        </ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          {' '}
          <div className="col-12">
            <AllDocumentTemplatesGrid />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AllDocumentTemplatesView
