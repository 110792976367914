import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  IFilter,
  Inject,
  Sort,
  Toolbar,
  Page,
  RecordClickEventArgs,
  ForeignKey,
  ExcelExport,
  Grid,
} from '@syncfusion/ej2-react-grids'

import { Loading } from 'common/components/Loading'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import * as stylesConfig from 'common/styles/stylesConfig'
import * as helpers from 'common/helpers'
import { useGetAllClients } from 'coredata/hooks/useClients'
import { ClientAggregate } from 'coredata/models/client/ClientAggregate'
import { useGetAllOffices } from 'coredata/hooks/useOffices'
import { ErrorContainer } from 'common/components'
import { ClickEventArgs } from '@syncfusion/ej2-react-buttons'

L10n.load(SYNCFUSIONTRANSLATIONS)

const AllClientsGrid = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()

  let grid: Grid | null
  const allClientData = useGetAllClients()
  const allOfficesData = useGetAllOffices()

  let toolbarOptions: any = [
    'Search',
    {
      text: 'Export',
      tooltipText: 'Export to Excel',
      prefixIcon: 'bi bi-file-earmark-spreadsheet',
      id: 'excelExport',
      align: 'Right',
    },
  ]

  let check: IFilter = {
    type: 'CheckBox',
  }
  let FilterType: any = {
    type: 'Menu',
  }

  function recordClicked(args: RecordClickEventArgs) {
    const clickedClientRecord = args.rowData as ClientAggregate
    navigate('/client/' + clickedClientRecord.id)
  }

  function officeTemplate(props: any) {
    const office = allOfficesData.data?.find((o) => o.id === props.officeId)
    return office ? office.name : props.officeId
  }

  const toolbarClick = (args: any) => {
    console.log(args)
    if (grid && args.item.id === 'excelExport') {
      grid.excelExport()
    }
  }

  return (
    <>
      {(allClientData.isFetching || allOfficesData.isFetching) && <Loading />}
      {allClientData.error && <ErrorContainer error={allClientData.error} />}
      {!allClientData.isFetching && !allOfficesData.isFetching && (
        <StyledGrid
          ref={(g) => (grid = g)}
          dataSource={allClientData.data}
          toolbar={toolbarOptions}
          allowExcelExport={true}
          toolbarClick={toolbarClick}
          allowPaging={true}
          filterSettings={FilterType}
          allowFiltering={true}
          allowSorting={true}
          sortSettings={{ columns: [{ field: 'name', direction: 'Ascending' }] }}
          pageSettings={{ pageSize: 15, pageCount: 5 }}
          enableHover={true}
          enableHeaderFocus={true}
          locale={locale}
          allowSelection={true}
          recordClick={recordClicked.bind(this)}
        >
          <ColumnsDirective>
            <ColumnDirective
              headerText="Klantnr"
              field="externalId"
              width="75"
              textAlign="Left"
              allowFiltering={true}
            />
            <ColumnDirective
              headerText="Naam"
              field="name"
              width="200"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="Email"
              field="email.value"
              width="100"
              textAlign="Left"
              allowFiltering={false}
            />
            <ColumnDirective
              headerText="BTWnr"
              field="vatNumber"
              width="100"
              textAlign="Left"
              allowFiltering={true}
            />
            <ColumnDirective
              headerText="Office"
              field="officeId"
              foreignKeyValue="name"
              foreignKeyField="id"
              dataSource={allOfficesData.data}
              width="100"
              textAlign="Left"
              filter={check}
            />
          </ColumnsDirective>
          <Inject services={[ForeignKey, Filter, Sort, Toolbar, Page, ExcelExport]} />
        </StyledGrid>
      )}
    </>
  )
}

export default AllClientsGrid

const StyledGrid = styled(GridComponent)`
  .e-toolbar .e-toolbar-items {
    background-color: ${stylesConfig.colorDarkPrimary};
  }

  cursor: pointer;
`
