import { toCamelCase } from 'common/helpers'
import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'
import { DocumentTemplateAggregate } from 'docgen/models/documenttemplate/DocumentTemplateAggregate'

export const DocumentTypeText = (data: DocumentAggregate) => {
  let type = data.type
  return toCamelCase(type.toString())
}

export const DocumentTemplateTypeText = (data: DocumentTemplateAggregate) => {
  let type = data.documentType

  return toCamelCase(type.toString())
}
