import React from 'react'

import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'

import AllTimesheetsGrid from 'admindata/components/AllTimesheetsGrid'

const AllTimesheetsView = () => {
  return (
    <>
      <div className="container-fluid">
        <TitleContainer title="PJS TimeSheets" />
        <ActionsBarContainer></ActionsBarContainer>
      </div>
      <ContentContainer>
        <div className="row">
          {' '}
          <div className="col-12">
            <AllTimesheetsGrid />
          </div>
        </div>
      </ContentContainer>
    </>
  )
}

export default AllTimesheetsView
