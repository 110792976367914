import { stringIsNumber } from 'common/helpers'

export enum DocumentType {
  Contract = 1,
  Offer = 2,
}

export const DocumentTypeArray = (): string[] => {
  return Object.keys(DocumentType)
    .filter(stringIsNumber)
    .map((key) => DocumentType[key])
}
