import React, { useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ColumnDirective,
  ColumnsDirective,
  Edit,
  EditSettingsModel,
  GridComponent,
  Inject,
  Page,
  Toolbar,
} from '@syncfusion/ej2-react-grids'
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from '@syncfusion/ej2-react-navigations'
import { TextBoxComponent, UploaderComponent } from '@syncfusion/ej2-react-inputs'
import { TitleContainer, ContentContainer, ActionsBarContainer } from 'common/layout'
import { Loading, ErrorContainer, BoxContainer } from 'common/components'
import { DocumentStatusBadge } from 'docgen/components/document/DocumentStatusBadge'

import { L10n } from '@syncfusion/ej2-base'
import { useLocalize } from 'localize-react'
import { SYNCFUSIONTRANSLATIONS } from 'common/translations'
import { GetLoggedInUser } from 'services/auth/authService'
import { useGetClientById } from 'coredata/hooks/useClients'
import {
  useGetDocumentById,
  useGenerateDocument,
  useDownloadDocument,
  useUpdateDocument,
  useSendDocument,
} from 'docgen/hooks/useDocuments'
import { GenerateDocumentCmd } from 'docgen/contracts/document/GenerateDocumentCmd'
import { DocumentAggregate } from 'docgen/models/document/DocumentAggregate'

import * as stylesConfig from 'common/styles/stylesConfig'
import styled from 'styled-components'
import * as helpers from 'common/helpers'
import { useGetDocumentTemplateById } from 'docgen/hooks/useDocumentTemplates'
import { FieldValue } from 'docgen/models/document/FieldValue'
import { UpdateDocumentCmd } from 'docgen/contracts/document/UpdateDocumentCmd'
import { ValueHolders } from 'docgen/models/document/ValueHolders'
import {
  ButtonDelete,
  ButtonDownload,
  ButtonEdit,
  ButtonLoadingAction,
} from 'common/components/Buttons'
import {
  AlertDialogArgs,
  AnimationSettingsModel,
  ButtonPropsModel,
  DialogComponent,
  DialogUtility,
} from '@syncfusion/ej2-react-popups'
import { Recipient } from 'docgen/models/document/Recipient'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import { useGetAllOffices, useGetOfficeById } from 'coredata/hooks/useOffices'
import {
  DocumentStatusEditAllowedList,
  DocumentStatusGenerateAllowedList,
  DocumentStatusSendAllowedList,
} from 'docgen/models/document/DocumentStatus'

L10n.load(SYNCFUSIONTRANSLATIONS)

const DocumentView = () => {
  const { locale } = useLocalize()
  const navigate = useNavigate()
  let { id } = useParams()
  let documentFieldValues = [] as FieldValue[]
  // HOOKS
  const documentData = useGetDocumentById(id)
  const clientData = useGetClientById(documentData.data?.clientId || '')
  const documentTemplateData = useGetDocumentTemplateById(
    documentData.data?.documentTemplateId || ''
  )
  const allOfficesData = useGetAllOffices()
  const officeData = useGetOfficeById(documentData.data?.officeId || '')
  const downloadDocument = useDownloadDocument(id)
  const generateDocument = useGenerateDocument(id)
  const sendDocument = useSendDocument(id)
  const updateDocument = useUpdateDocument()
  const loggedInUser = GetLoggedInUser()
  loadMappedDocumentFieldValues()

  // Global
  const clientTextTemplate = (data: DocumentAggregate | undefined) => {
    if (clientData.data === undefined) return ''

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href="#"
        onClick={() => {
          navigate('/client/' + clientData?.data?.id)
        }}
      >
        {clientData.data?.name}
      </a>
    )
  }

  // EDIT
  let promptDialogInstance = useRef<DialogComponent>(null)
  let editDocumentButtons: ButtonPropsModel[]
  let animationSettings: AnimationSettingsModel
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  let nameInput: TextBoxComponent | null
  const [changeOfficeId, setChangeOfficeId] = useState(documentData.data?.officeId)
  const [changeRecipient, setChangeRecipient] = useState(documentData.data?.recipient)

  editDocumentButtons = [
    {
      click: () => {
        const updatedDocument = {
          id: documentData.data?.id,
          name: nameInput?.value,
          officeId: changeOfficeId ? changeOfficeId : documentData.data?.officeId,
          recipient: changeRecipient ? changeRecipient : documentData.data?.recipient,
          values: documentData.data?.values,
          updatedBy: loggedInUser.username,
        } as UpdateDocumentCmd

        updateDocument.mutate(updatedDocument)
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Save', isPrimary: true },
    },
    {
      click: () => {
        setEditDialogOpen(false)
      },
      buttonModel: { content: 'Cancel' },
    },
  ]
  animationSettings = { effect: 'None' }
  function btnEditDocumentClick() {
    setEditDialogOpen(true)
  }
  function editDocumentDialogClose() {
    setEditDialogOpen(false)
  }

  // GENERATE
  function btnGenerateClick() {
    let unsavedChanges: any = gridFields.current?.editModule.getBatchChanges()
    if (unsavedChanges.changedRecords.length > 0) {
      DialogUtility.alert({
        content:
          'There are unsaved changes in the Document Fields. <br/>Please Update or Cancel them first.',
        title: 'Unsaved changes',
      } as AlertDialogArgs)
      return
    }
    if (generateDocument.isLoading) {
      DialogUtility.alert({
        content:
          'The document is already being created in the background. <br/> Please wait for the generation to complete.',
        title: 'Document generation is still in progress...',
      } as AlertDialogArgs)
      return
    }
    if (documentData.data === undefined) {
      DialogUtility.alert({
        content:
          "There is a problem with the document you're trying to generate. Please refresh the page and try again.",
        title: 'Document Error',
      } as AlertDialogArgs)
      return
    }
    if (!helpers.checkEnumInList(documentData.data?.status, DocumentStatusGenerateAllowedList)) {
      DialogUtility.alert({
        content: 'Unable to generate the document. The document has already been sent or signed.',
        title: 'Document can not be generated',
      } as AlertDialogArgs)
      return
    }

    try {
      const generateDocumentCmd = {
        generatedBy: loggedInUser.username,
      } as GenerateDocumentCmd

      generateDocument.mutate(generateDocumentCmd)
    } catch (err) {
      console.error(err)
    }
  }

  // SEND
  function btnSendClick() {
    if (generateDocument.isLoading) {
      DialogUtility.alert({
        content:
          'The document is being created in the background. <br/> Please wait for the generation to complete.',
        title: 'Document generation is still in progress...',
      } as AlertDialogArgs)
      return
    }
    if (!documentData.data?.meta?.createdDocumentBlob) {
      DialogUtility.alert({
        content: 'The document is not yet ready. Please generate it first.',
        title: 'Unable to send document',
      } as AlertDialogArgs)
      return
    }

    if (!helpers.checkEnumInList(documentData.data?.status, DocumentStatusSendAllowedList)) {
      DialogUtility.alert({
        content: 'The document has already been sent or signed.',
        title: 'Document can not be sent',
      } as AlertDialogArgs)
      return
    }

    try {
      sendDocument.mutate()
    } catch (err) {
      console.error(err)
    }
  }

  // DOWNLOAD
  async function btnDownloadClick() {
    if (generateDocument.isLoading) {
      DialogUtility.alert({
        content:
          'The document is being created in the background. <br/> Please wait for the generation to complete.',
        title: 'Document generation is still in progress...',
      } as AlertDialogArgs)
    } else if (!documentData.data?.meta?.createdDocumentBlob) {
      DialogUtility.alert({
        content: 'The document is not yet ready. Please generate it first.',
        title: 'Unable to download document',
      } as AlertDialogArgs)
    } else {
      try {
        await downloadDocument.refetch().then((res) => {
          if (res.data) {
            var data = window.URL.createObjectURL(res.data)
            var link = document.createElement('a')
            document.body.appendChild(link)
            link.href = data
            link.target = '_blank'
            link.download = documentData.data?.number + '-' + documentData.data?.name + '.pdf'
            link.click()
            window.URL.revokeObjectURL(data)
            link.remove()
          }
        })
      } catch (err) {
        console.log(err)
      }
    }
  }

  // DOCUMENT VALUES GRID
  let gridFields = useRef<GridComponent>(null)
  const toolbarOptions: any = [
    { text: 'Update', cssClass: 'gridCommandUpdate', align: 'right' },
    { text: 'Cancel', align: 'right' },
  ]
  const editSettings: EditSettingsModel = {
    allowEditing: helpers.checkEnumInList(documentData.data?.status, DocumentStatusEditAllowedList),
    allowAdding: false,
    allowDeleting: false,
    mode: 'Batch',
    showConfirmDialog: false,
  }

  const descriptionTemplate = (fieldValue: FieldValue) => {
    if (documentTemplateData?.data?.placeHolders?.fields)
      return (
        documentTemplateData?.data?.placeHolders?.fields?.find(
          (templateField) => templateField.name === fieldValue.name
        )?.description || ''
      )

    return '-'
  }

  // DOCUMENT VALUES FUNCTIONS
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function loadMappedDocumentFieldValues() {
    if (documentData.data && documentTemplateData.data) {
      if (documentData.data.values?.fields?.length === 0) {
        documentTemplateData.data?.placeHolders.fields?.map((x) => {
          documentFieldValues.push({
            name: x.name,
            value: x.defaultValue,
            type: x.type,
          } as FieldValue)
        })
      } else {
        documentTemplateData.data?.placeHolders.fields?.map((x) => {
          documentFieldValues.push({
            name: x.name,
            value: documentData?.data?.values?.fields?.find((v) => v.name === x.name)?.value || '',
            type: x.type,
          } as FieldValue)
        })
      }
    }
  }

  function updateFieldValues() {
    const src = gridFields.current?.dataSource
    const json = JSON.stringify(src)
    const newFieldValues = JSON.parse(json) as FieldValue[]

    if (newFieldValues.length > 0) {
      let currentDocument = documentData.data
      const updateCmd = {
        id: documentData.data?.id,
        name: currentDocument?.name,
        officeId: currentDocument?.officeId,
        recipient: currentDocument?.recipient,
        values: {
          fields: newFieldValues,
          tables: null,
        } as ValueHolders,
      } as UpdateDocumentCmd
      updateDocument.mutate(updateCmd)
    }
  }

  async function actionCompletedFields(args: any) {
    console.log('change')
    if (
      args.requestType === 'batchsave' &&
      args.name === 'actionComplete' &&
      args.cancel === false
    ) {
      try {
        await updateFieldValues()
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <>
      {(documentData.isFetching || documentTemplateData.isFetching) && <Loading />}
      {documentData.error && <ErrorContainer error={documentData.error} />}
      {documentData.data && documentTemplateData.error && (
        <ErrorContainer error={documentTemplateData.error} />
      )}
      {documentData.data && clientData.error && <ErrorContainer error={clientData.error} />}
      {documentData.data && generateDocument.error && (
        <ErrorContainer title={'Generate Document'} error={generateDocument.error} />
      )}
      {/*documentData.data && downloadDocument.error && (
        <ErrorContainer title={'Download Document'} error={downloadDocument.error} />
      )*/}
      {documentData.data && sendDocument.error && (
        <ErrorContainer title={'Send Document'} error={sendDocument.error} />
      )}
      {!documentData.isFetching && !documentData.error && (
        <>
          <div className="container-fluid">
            <TitleContainer
              title="Document Generator"
              subtitle={documentData.data?.name || 'Document not found'}
            />

            <ActionsBarContainer>
              {helpers.checkEnumInList(documentData.data?.status, DocumentStatusEditAllowedList) ? (
                <ButtonEdit onClick={btnEditDocumentClick}></ButtonEdit>
              ) : (
                ''
              )}{' '}
              {helpers.checkEnumInList(documentData.data?.status, DocumentStatusEditAllowedList) ? (
                <ButtonLoadingAction
                  onClick={btnGenerateClick}
                  loading={generateDocument.isLoading}
                  icon="bi bi-arrow-repeat"
                >
                  Generate
                </ButtonLoadingAction>
              ) : (
                ''
              )}{' '}
              <ButtonDownload onClick={btnDownloadClick} bgColor={stylesConfig.colorWhite}>
                PDF
              </ButtonDownload>{' '}
              <ButtonLoadingAction
                onClick={btnSendClick}
                loading={sendDocument.isLoading}
                disabled
                icon="bi bi-send"
              ></ButtonLoadingAction>{' '}
              <ButtonDelete
                onClick={() => alert('Are you sure you want to delete this document?')}
                disabled
              ></ButtonDelete>{' '}
            </ActionsBarContainer>
          </div>
          <ContentContainer>
            <div className="row" id="dialogTarget">
              <div className="col-8">
                <BoxContainer title="Document information">
                  Document Nr: <b>{documentData.data?.number}</b>
                  <br />
                  Client: <b>{clientTextTemplate(documentData.data)}</b>
                  <br />
                  Contact:{' '}
                  <b>
                    {documentData.data?.recipient?.name} ({documentData.data?.recipient?.email})
                  </b>
                  <br />
                  Office: <b>{officeData.data?.name}</b>
                  <br />
                  Template: <b>{documentTemplateData.data?.name}</b>
                  <br />
                  Type: <b>{documentData.data?.type}</b>
                  <br />
                  Status:{' '}
                  <b>{DocumentStatusBadge(documentData.data ?? ({} as DocumentAggregate))}</b>
                  <br />
                </BoxContainer>
              </div>
              <div className="col-4">
                <BoxContainer title="History">
                  Created By:{' '}
                  <b>{helpers.usernameToName(documentData.data?.auditData?.createdBy)}</b>
                  <br />
                  Created On: <b>{helpers.formatDate(documentData.data?.auditData?.createdOn)}</b>
                  <br />
                  Last Updated By:{' '}
                  <b>
                    {documentData.data?.auditData?.updateInfo &&
                    documentData.data?.auditData?.updateInfo.length > 0
                      ? helpers.usernameToName(
                          documentData.data?.auditData?.updateInfo.slice(-1).pop()?.updatedBy
                        )
                      : ''}
                  </b>
                  <br />
                  Last Updated On:{' '}
                  <b>
                    {helpers.formatDate(
                      documentData.data?.auditData?.updateInfo.slice(-1).pop()?.updatedOn
                    )}
                  </b>
                  <br />
                </BoxContainer>
              </div>
              <div className="col-12">
                <br />
                <StyledTabComponent heightAdjustMode="Auto" className="e-fill">
                  <TabItemsDirective>
                    <TabItemDirective
                      header={{ text: 'Document Fields' }}
                      content={contentDocumentValues}
                    />
                    <TabItemDirective
                      header={{ text: 'Document Attachments' }}
                      content={contentAttachments}
                    />
                  </TabItemsDirective>
                </StyledTabComponent>
              </div>
            </div>
          </ContentContainer>

          <StyledDialogComponent
            id="promptDialog"
            header={'Edit ' + documentData?.data?.name}
            visible={editDialogOpen}
            showCloseIcon={true}
            animationSettings={animationSettings}
            width="auto"
            height="auto"
            ref={promptDialogInstance}
            target="#dialogTarget"
            buttons={editDocumentButtons}
            close={editDocumentDialogClose.bind(this)}
            allowDragging={true}
          >
            {/* Prompt Dialog content  */}
            <table className="e-table" cellSpacing="6px">
              <tbody>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell" width="400">
                    <TextBoxComponent
                      type="text"
                      placeholder="Document Name"
                      value={documentData.data?.name}
                      floatLabelType="Always"
                      ref={(n) => (nameInput = n)}
                      autocomplete={'off'}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell">
                    <DropDownListComponent
                      id="ddlOffices"
                      key="offices"
                      placeholder="Office"
                      floatLabelType="Always"
                      dataSource={
                        allOfficesData.data
                          ? allOfficesData.data.map((o, key) => {
                              return { key: o }
                            })
                          : undefined
                      }
                      fields={{ text: 'key.name', value: 'key.id' }}
                      value={documentData.data?.officeId}
                      change={(event) => {
                        setChangeOfficeId(event.value as string)
                      }}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
                <StyledDialogTr>
                  <StyledDialogTd className="e-rowcell">
                    <DropDownListComponent
                      id="ddlContacts"
                      key="contacts"
                      placeholder="Contact"
                      floatLabelType="Always"
                      dataSource={
                        clientData.data?.contacts
                          ? clientData.data.contacts.map((o, key) => {
                              return { key: o }
                            })
                          : undefined
                      }
                      fields={{
                        text: 'key.email.value',
                        value: 'key.id',
                      }}
                      delayUpdate="true"
                      value={documentData.data?.recipient?.email}
                      change={(contactId) => {
                        const contact = clientData.data?.contacts?.find(
                          (a) => a.id === contactId.value
                        )
                        if (contact !== undefined)
                          setChangeRecipient({
                            email: contact.email.value,
                            name: contact.firstName + ' ' + contact.lastName,
                          } as Recipient)
                      }}
                    />
                  </StyledDialogTd>
                </StyledDialogTr>
              </tbody>
            </table>
          </StyledDialogComponent>
        </>
      )}
    </>
  )

  function contentDocumentValues() {
    let allowEditing = helpers.checkEnumInList(
      documentData.data?.status,
      DocumentStatusEditAllowedList
    )
    const customStylesDisabled = { class: 'gridCellDisabled' }
    const customStylesEditable = { class: 'gridCellEditable' }

    return (
      <StyledTabContent>
        <BoxContainer title="Document Fields">
          <StyledGrid
            ref={gridFields}
            dataSource={documentFieldValues}
            editSettings={editSettings}
            toolbar={
              helpers.checkEnumInList(documentData.data?.status, DocumentStatusEditAllowedList)
                ? toolbarOptions
                : undefined
            }
            actionComplete={actionCompletedFields}
            selectionSettings={{ type: 'Single', mode: 'Row' }}
          >
            <ColumnsDirective>
              <ColumnDirective
                headerText="Field"
                field="name"
                width="auto"
                textAlign="Left"
                allowEditing={false}
                isPrimaryKey={true}
                uid="id"
                customAttributes={customStylesDisabled}
              />
              <ColumnDirective
                headerText="Description"
                field="name"
                width="auto"
                textAlign="Left"
                allowEditing={false}
                uid="description"
                template={descriptionTemplate}
                customAttributes={customStylesDisabled}
              />
              <ColumnDirective
                headerText="Value"
                textAlign="Left"
                field="value"
                allowEditing={allowEditing}
                uid="name"
                customAttributes={
                  helpers.checkEnumInList(documentData.data?.status, DocumentStatusEditAllowedList)
                    ? customStylesEditable
                    : customStylesDisabled
                }
              />
            </ColumnsDirective>
            <Inject services={[Page, Toolbar, Edit]} />
          </StyledGrid>
        </BoxContainer>
      </StyledTabContent>
    )
  }

  function contentAttachments() {
    return (
      <StyledTabContent>
        Add extra attachments
        <UploaderComponent />
      </StyledTabContent>
    )
  }
}

export default DocumentView

const StyledGrid = styled(GridComponent)`
  cursor: pointer;
`
const StyledTabComponent = styled(TabComponent)`
  div.e-content {
    height: unset !important;
  }
`
const StyledTabContent = styled.div`
  margin: 15px 0;
  padding-bottom: 15px;
`

const StyledDialogComponent = styled(DialogComponent)`
  max-height: unset !important;
  top: 20px !important;
  height: auto !important;
  width: auto !important;
  min-width: 450px !important;
  min-height: 300px !important;
`
const StyledDialogTr = styled.tr``
const StyledDialogTd = styled.td`
  padding: 5px 50px 20px 0;
`
