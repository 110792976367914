import React, { createContext, useContext } from 'react'
import axios, { AxiosHeaders, AxiosInstance } from 'axios'
import { GetMsalClientScope, GetWebApiBaseUri } from 'services/appsettings'
import { useAccount, useMsal } from '@azure/msal-react'
import { AccountInfo, SilentRequest } from '@azure/msal-browser'

export type ProviderValue = AxiosInstance
export type DefaultValue = undefined
export type ContextValue = DefaultValue | ProviderValue
export const AxiosContext = createContext<ContextValue>(undefined)

export function useAxios() {
  return useContext(AxiosContext)
}

export default function AxiosProvider({ children }: React.PropsWithChildren<unknown>) {
  const apiUrl = GetWebApiBaseUri()

  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  let tokenRequest: SilentRequest = {
    account: account as AccountInfo,
    scopes: [GetMsalClientScope()],
  }

  const axiosMemo = axios.create({
    baseURL: apiUrl,
    headers: {
      'Content-type': 'application/json',
    },
  })

  axiosMemo.interceptors.request.use(
    async function (config) {
      const token = await instance.acquireTokenSilent(tokenRequest)
      if (config.headers) {
        config.headers.Authorization = `Bearer ${token.accessToken}`
      }

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  return <AxiosContext.Provider value={axiosMemo}>{children}</AxiosContext.Provider>
}
