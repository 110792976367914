import styled from 'styled-components'
import * as stylesConfig from 'common/styles/stylesConfig'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons'
import { ProgressButtonComponent, SpinSettingsModel } from '@syncfusion/ej2-react-splitbuttons'
import React from 'react'

/*
 *********************
 *** Default
 **********************/
interface PropsDefault {
  children?: React.ReactNode
  onClick?: () => void
  float?: string
  color?: string
  bgColor?: string
  id?: string
  disabled?: boolean
  icon?: string
  marginright?: string
}

export const ButtonDefault = (props: PropsDefault) => {
  return (
    <StyledDefaultButton
      bgColor={props.bgColor}
      color={props.color}
      disabled={props.disabled}
      id={props.id}
      onClick={props.onClick}
      marginright={props.marginright}
    >
      <i className={'bi ' + (props.icon ? props.icon : '')} /> {props.children}
    </StyledDefaultButton>
  )
}

const StyledDefaultButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.marginright && 'margin-right: ' + props.marginright};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
` as React.FC<PropsDefault>

/*
 *********************
 *** Add
 **********************/
interface PropsAdd {
  float?: string
  color?: string
  bgColor?: string
  children?: React.ReactNode
}

export const ButtonAdd = (props: PropsAdd) => {
  return (
    <StyledAddButton>
      <i className="bi bi-plus-circle" /> {props.children}
    </StyledAddButton>
  )
}

const StyledAddButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
`

/*
 *********************
 *** Edit
 **********************/
interface PropsEdit {
  float?: string
  color?: string
  bgColor?: string
  children?: React.ReactNode
  onClick?: () => void
  id?: string
  disabled?: boolean
}

export const ButtonEdit = (props: PropsEdit) => {
  return (
    <StyledEditButton
      disabled={props.disabled}
      id={props.id}
      onClick={props.onClick}
      cssClass="e-warning"
    >
      <i className="bi bi-pencil" /> {props.children}
    </StyledEditButton>
  )
}

const StyledEditButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
`

/*
 *********************
 *** Delete
 **********************/
interface PropsDelete {
  float?: string
  color?: string
  bgColor?: string
  children?: React.ReactNode
  onClick?: () => void
  id?: string
  disabled?: boolean
}

export const ButtonDelete = (props: PropsDelete) => {
  return (
    <StyledDeleteButton
      disabled={props.disabled}
      id={props.id}
      onClick={props.onClick}
      cssClass="e-danger"
    >
      <i className="bi bi-trash" /> {props.children}
    </StyledDeleteButton>
  )
}

const StyledDeleteButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
`

/*
 *********************
 *** Open
 **********************/
interface PropsOpen {
  float?: string
  color?: string
  bgColor?: string
  children?: React.ReactNode
  onClick?: () => void
  id?: string
  disabled?: boolean
  icon?: string
}

export const ButtonOpen = (props: PropsOpen) => {
  return (
    <StyledOpenButton
      disabled={props.disabled}
      id={props.id}
      onClick={props.onClick}
      cssClass="e-warning"
    >
      <i className={'bi ' + (props.icon ? props.icon : 'bi-folder2-open')} /> {props.children}
    </StyledOpenButton>
  )
}

const StyledOpenButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
`

/*
 *********************
 *** Download
 **********************/
interface PropsDownload {
  float?: string
  color?: string
  bgColor?: string
  children?: React.ReactNode
  onClick?: () => void
  id?: string
  disabled?: boolean
  icon?: string
}

export const ButtonDownload = (props: PropsDownload) => {
  return (
    <StyledDownloadButton
      disabled={props.disabled}
      id={props.id}
      onClick={props.onClick}
      bgColor={props.bgColor}
      color={props.color}
    >
      <i className={'bi ' + (props.icon ? props.icon : 'bi-download')} /> {props.children}
    </StyledDownloadButton>
  )
}

const StyledDownloadButton = styled(ButtonComponent)`
  ${(props) => props.float && 'float: ' + props.float};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.bgColor && 'background-color: ' + props.bgColor};
  ${(props) => props.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.disabled && 'pointer-events: all !important;'}
` as React.FC<PropsDownload>

/*
 *********************
 *** ButtonLoadingAction
 **********************/

interface PropsLoadingAction {
  className?: string | undefined
  bgColor?: string | undefined
  color?: string | undefined
  onClick: () => void
  loading: boolean
  children?: React.ReactNode
  icon?: string
  title?: string
  disabled?: boolean
}
interface PropsLoadingActionStyles {
  stylesProps: PropsLoadingAction
}

const spinCenter: SpinSettingsModel = { position: 'Center' }

export const ButtonLoadingAction = (props: PropsLoadingAction) => {
  if (props.loading)
    return (
      <StyledProgressButton
        className={props.className}
        disabled={true}
        spinSettings={spinCenter}
        enableProgress={false}
        duration={45000}
        color={props.color}
        stylesProps={props}
      >
        {props.icon && <i style={{ marginRight: '5px' }} className={props.icon} />}
        {props.children}
      </StyledProgressButton>
    )
  else
    return (
      <StyledProgressButton
        stylesProps={props}
        className={props.className}
        spinSettings={spinCenter}
        enableProgress={false}
        duration={3000}
        onClick={props.onClick}
        disabled={props.disabled}
        color={props.color}
      >
        {props.icon && <i style={{ marginRight: '5px' }} className={props.icon} />}
        {props.children}
      </StyledProgressButton>
    )
}

const StyledProgressButton = styled(ProgressButtonComponent)<PropsLoadingActionStyles>`
  background-color: ${(props) => {
    if (props.stylesProps.bgColor) return props.stylesProps.bgColor
    else return stylesConfig.colorLightPrimary
  }};
  ${(props) => props.color && 'color: ' + props.color};
  ${(props) => props.stylesProps.disabled && 'cursor: not-allowed !important;'}
  ${(props) => props.stylesProps.disabled && 'pointer-events: all !important;'}
`
