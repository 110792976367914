import { useMutation, useQuery } from 'react-query'
import { queryClient } from 'services/http/queryClient'
import { useAxios, ProviderValue } from 'services/http/axiosClient'
import { ResponseError } from 'services/http/types'
import { ReturnId } from 'common/models/ReturnTypes'

import { DocumentTemplateAggregate } from 'docgen/models/documenttemplate/DocumentTemplateAggregate'
import { AddDocumentTemplateCmd } from 'docgen/contracts/documenttemplate/AddDocumentTemplateCmd'
import { UpdateDocumentTemplatePlaceholdersCmd } from 'docgen/contracts/documenttemplate/UpdateDocumentTemplatePlaceholdersCmd'
import { UpdateDocumentTemplateDetailsCmd } from 'docgen/contracts/documenttemplate/UpdateDocumentTemplateDetailsCmd'
import { stringIsEmpty } from 'common/helpers'

export const getAllDocumentTemplatesKey = 'getAllDocumentTemplates'
export const getDocumentTemplateByIdKey = 'getDocumentTemplateById'
export const addDocumentTemplateKey = 'addDocumentTemplate'
export const updateDocumentTemplateKey = 'updateDocumentTemplate'

export function useGetAllDocumentTemplates() {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentTemplateAggregate[], ResponseError>(
    getAllDocumentTemplatesKey,
    async () => {
      const { data } = await axiosClient.get<DocumentTemplateAggregate[]>('/document-template/all')

      return data
    }
  )
}

export function useGetDocumentTemplateById(id: string) {
  const axiosClient = useAxios() as ProviderValue

  return useQuery<DocumentTemplateAggregate | undefined, ResponseError>(
    [getDocumentTemplateByIdKey, id],
    async () => {
      if (stringIsEmpty(id)) return undefined
      const { data } = await axiosClient.get<DocumentTemplateAggregate>(`/document-template/${id}`)

      return data
    }
  )
}

export function useAddDocumentTemplate() {
  const axiosClient = useAxios() as ProviderValue

  return useMutation<string, ResponseError, AddDocumentTemplateCmd, unknown>(
    addDocumentTemplateKey,
    async (addDocumentTemplate: AddDocumentTemplateCmd) => {
      const { data } = await axiosClient.post<ReturnId>(`/document-template`, addDocumentTemplate)
      return data.id
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(getAllDocumentTemplatesKey)
      },
    }
  )
}

export function useUpdateDocumentTemplatePlaceholders() {
  const axiosClient = useAxios() as ProviderValue
  let updatedDocTemplate: UpdateDocumentTemplatePlaceholdersCmd
  return useMutation<{}, ResponseError, UpdateDocumentTemplatePlaceholdersCmd, unknown>(
    [],
    async (updateDocTemplate: UpdateDocumentTemplatePlaceholdersCmd) => {
      updatedDocTemplate = updateDocTemplate
      const { data } = await axiosClient.put(`/document-template/placeholders`, updateDocTemplate)
      return data
    },
    {
      onSuccess: (data) => {
        console.log('OnSuccess update template placeholders', updatedDocTemplate)
        queryClient.setQueryData<DocumentTemplateAggregate | undefined>(
          [getDocumentTemplateByIdKey, updatedDocTemplate.id],
          (old) => {
            if (old) {
              old.placeHolders = updatedDocTemplate.placeHolders
              return old
            }
          }
        )
      },
    }
  )
}

export function useUpdateDocumentTemplateDetails() {
  const axiosClient = useAxios() as ProviderValue
  let updatedDocTemplate: UpdateDocumentTemplateDetailsCmd
  return useMutation<{}, ResponseError, UpdateDocumentTemplateDetailsCmd, unknown>(
    [],
    async (updateDocTemplate: UpdateDocumentTemplateDetailsCmd) => {
      updatedDocTemplate = updateDocTemplate
      const { data } = await axiosClient.put(`/document-template/details`, updateDocTemplate)
      return data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData<DocumentTemplateAggregate | undefined>(
          [getDocumentTemplateByIdKey, updatedDocTemplate.id],
          (old) => {
            if (old) {
              old.name = updatedDocTemplate.name
              old.documentUrl = updatedDocTemplate.documentUrl
              return old
            }
          }
        )
      },
    }
  )
}
