import { QueryFunction } from 'react-query'

export function getResponseErrorMessage(error: any): string | undefined {
  if (error === null) return undefined

  if (error.response !== undefined && error.response.data !== undefined) {
    return error.response.data.detail
  }
  if (error instanceof Response) {
    return error.statusText
  }
  if (error instanceof Error) {
    return error.message
  }
  return undefined
}

export function getResponseErrorTitle(error: any): string | undefined {
  if (error.response !== undefined)
    return error.name + ' ' + error.response.status + ' ' + error.response.statusText

  return error.name
}

export function withQueryCancellation<T = unknown>(
  cb: (signal: AbortSignal, ...args: unknown[]) => Promise<T>
): QueryFunction<T> {
  return (...args) => {
    const controller = new AbortController()
    const signal = controller.signal
    const promise = cb(signal, ...args)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    promise.cancel = () => controller.abort()
    return promise
  }
}
