import React from 'react'
import AppRoutes from 'services/routes'
import { GetLoggedInUser } from 'services/auth/authService'
import styled from 'styled-components'
import logo from 'assets/logo.png'
import * as stylesConfig from 'common/styles/stylesConfig'

import TopBarMenu from './TopBarMenu'
import SideBar from './SideBar'
import { GetWebAppCustomNodeEnv } from 'services/appsettings'

const MainLayout = () => {
  const account = GetLoggedInUser()

  let webAppEnv = GetWebAppCustomNodeEnv()

  //open the sidebar
  function openClick() {
    let sidebar = (document.getElementById('sidebar-menu') as any).ej2_instances[0]
    sidebar.toggle()
  }
  function closeStagingNotice() {
    let notice = document.getElementById('stagingNoticeBanner')
    notice?.remove()
  }
  return (
    <>
      {account ? (
        <div id="wrapper">
          <div className="col-lg-12 col-sm-12 col-md-12">
            {(webAppEnv === 'development' || webAppEnv === 'staging') && (
              <StagingNoticeBanner
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
                id="stagingNoticeBanner"
              >
                <i className="bi bi-exclamation-triangle-fill" />
                <strong>This is a demo/test environment. All data will be deleted.</strong>
                <button
                  title="close"
                  type="button"
                  className="btn-close"
                  onClick={closeStagingNotice}
                />
              </StagingNoticeBanner>
            )}
            <div className="header-section dock-menu" id="header">
              <StyledHeaderUl className="header-list">
                <StyledLogoDiv className="list">
                  <img src={logo} height="40px" alt="MetiSelect" />
                </StyledLogoDiv>
                <li
                  id="hamburger"
                  className="icon-menu icon list"
                  onClick={openClick.bind(this)}
                ></li>

                <TopBarMenu loggedInAccountInfo={account} />
              </StyledHeaderUl>
            </div>
            <SideBar />
            <div className="main-content" id="maintext">
              <ContentWrapper className="sidebar-menu-content">
                <ViewWrapper>
                  <AppRoutes />
                </ViewWrapper>
              </ContentWrapper>
            </div>
          </div>
        </div>
      ) : (
        <div>Authentication problem. Not logged in correctly!</div>
      )}
    </>
  )
}

const ContentWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`
const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  background-color: ${stylesConfig.colorBackground} !important;
  height: 100%;
  overflow-y: auto;
`
const StyledHeaderUl = styled.ul`
  margin: 0;
  padding-left: 0px;
`
const StyledLogoDiv = styled.div`
  float: left;
  height: 50px;
  padding: 5px;
  text-align: center;
  @media (min-width: 600px) {
    width: 220px;
  }
`
const StagingNoticeBanner = styled.div`
  text-align: center;
  margin-bottom: 0;
`
export default MainLayout
